const CARDS = [
    {
        "id": 1,
        "name_cn": "研究",
        "description_cn": "研究或關注特定主題",
        "name_en": "Research",
        "description_en": "Study or focus on a specific topic\n",
        "reason": [
            {
                "reason_en": "Love studying, thinking and researching\n",
                "reason_cn": "喜歡鑽研、思考、研究",
                "type": 1
            },
            {
                "reason_en": "Enjoy learning new knowledge",
                "reason_cn": "喜歡學習新知識",
                "type": 1
            },
            {
                "reason_en": "Enjoy searching for solution",
                "reason_cn": "喜歡尋找解決方法",
                "type": 1
            },
            {
                "reason_en": "Understanding economic trend \nand financial pulse",
                "reason_cn": "了解經濟趨勢、財經脈動",
                "type": 8
            },
            {
                "reason_en": "Pay attention to social issues",
                "reason_cn": "關心社會議題",
                "type": 4
            },
            {
                "reason_en": "Provide necessary information to others",
                "reason_cn": "提供他人需要的資訊",
                "type": 11
            },
            {
                "reason_en": "Able to try hands-on operation or assembly",
                "reason_cn": "可以自己試著動手操作或組裝",
                "type": 6
            },
            {
                "reason_en": "Enjoy understanding a topic thoroughly",
                "reason_cn": "喜歡透徹了解某事物",
                "type": 1
            },
            {
                "reason_en": "Knowledge is power",
                "reason_cn": "知識就是力量",
                "type": 9
            },
            {
                "reason_en": "Able to communicate and exchange with others（或able to interact and held in-depth communicate with others）",
                "reason_cn": "可與他人交流、深入對談",
                "type": 4
            },
            {
                "reason_en": "Study or pay attention to issues of environmental protection, natural environment, plants & animals, and health",
                "reason_cn": "研究或關注環保、自然環境、動植物、健康等相關議題",
                "type": 12
            },
            {
                "reason_en": "Able to persuade others",
                "reason_cn": "可說服他人",
                "type": 10
            },
            {
                "reason_en": "Improve equipping self and upgrade personal level to become competitive",
                "reason_cn": "提升自我配備或層次才有競爭力",
                "type": 9
            },
            {
                "reason_en": "Love categorizing and organizing information",
                "reason_cn": "喜歡分類、整理好資料",
                "type": 7
            },
            {
                "reason_en": "Improving quality of life",
                "reason_cn": "提高生活品質",
                "type": 5
            },
            {
                "reason_en": "Study or pay attention to philosophy, religion, and spirituality",
                "reason_cn": "或關注哲學、宗教、心靈等議題",
                "type": 2
            },
            {
                "reason_en": "Study or focus on art topics",
                "reason_cn": "研究或關注藝術主題",
                "type": 3
            },
            {
                "reason_en": "Study political issues",
                "reason_cn": "研究政治議題",
                "type": 9
            },
            {
                "reason_en": "Help others tackle their problems",
                "reason_cn": "協助他人解決問題",
                "type": 11
            },
            {
                "reason_en": "Able to have topics to talk to others",
                "reason_cn": "可和他人有話題聊",
                "type": 10
            },
            {
                "reason_en": "Productions made by myself are practical",
                "reason_cn": "自己研究做出來的物品很實用",
                "type": 6
            }
        ]
    },
    {
        "id": 2,
        "name_cn": "學習",
        "description_cn": "學習知識\r\n學習語言\r",
        "name_en": "Learning\r",
        "description_en": "Learning knowledge or language",
        "reason": [
            {
                "reason_en": "To improve self-capability (for raise, promotion and better job)",
                "reason_cn": "提升個人能力（加薪、升遷、好找工作）",
                "type": 9
            },
            {
                "reason_en": "Communicate with others or get new acquaintance",
                "reason_cn": "與人溝通、交朋友",
                "type": [4, 10]
            },
            {
                "reason_en": "Personal interest in language and learning",
                "reason_cn": "對語言/學習本身有興趣",
                "type": 1
            },
            {
                "reason_en": "Requirement of further education",
                "reason_cn": "升學需要",
                "type": 1
            },
            {
                "reason_en": "Enjoy the regularity of language",
                "reason_cn": "喜歡語言的規則性",
                "type": 7
            },
            {
                "reason_en": "Increase practicality",
                "reason_cn": "增加實用性",
                "type": 6
            },
            {
                "reason_en": "Knowledge is power",
                "reason_cn": "知識就是力量",
                "type": 9
            },
            {
                "reason_en": "Enrich self and pursuit of dreams",
                "reason_cn": "充實自我、追求夢想",
                "type": 9
            },
            {
                "reason_en": "A sense of achievement",
                "reason_cn": "有成就感",
                "type": 9
            },
            {
                "reason_en": "Learn for persuasion",
                "reason_cn": "學習新知可以說服他人",
                "type": 10
            },
            {
                "reason_en": "Increase business opportunity and competitiveness",
                "reason_cn": "增加商機、提升競爭力",
                "type": 8
            },
            {
                "reason_en": "Spiritual satisfaction and self-fulfillment",
                "reason_cn": "心靈滿足、自我實現",
                "type": 2
            },
            {
                "reason_en": "Learning by itself is fun",
                "reason_cn": "學習本身很快樂",
                "type": 1
            },
            {
                "reason_en": "Love contemplating",
                "reason_cn": "喜歡動腦思考",
                "type": 1
            },
            {
                "reason_en": "Continuous learning can prevent brain degeneration",
                "reason_cn": "持續學習可以預防大腦退化",
                "type": 12
            },
            {
                "reason_en": "Feel steady by arranging study plans and completing accordingly",
                "reason_cn": "安排讀書計畫，依序完成很踏實",
                "type": 7
            },
            {
                "reason_en": "Love sorting notes",
                "reason_cn": "喜歡整理筆記7",
                "type": 7
            },
            {
                "reason_en": "Love to travel (please refer to “traveling” section)",
                "reason_cn": "想去旅行 (請參考「旅行」活動)",
                "type": 0
            }
        ]
    },
    {
        "id": 3,
        "name_cn": "益智",
        "description_cn": "下棋、觀棋、桌遊、數獨、麻將、撲克牌、七巧板、益智遊戲、動腦活動",
        "name_en": "Puzzle games",
        "description_en": "Chess playing, watching chess, board games, Sudoku, mahjong, playing cards, jigsaw puzzle, Puzzle games, Thought activities\r",
        "reason": [
    {
        "reason_en": "Love contemplating",
        "reason_cn": "喜歡動腦思考",
        "type": 1
    },
    {
        "reason_en": "Enjoy analyzing the situation",
        "reason_cn": "喜歡分析局勢",
        "type": 1
    },
    {
        "reason_en": "Enjoy psychological game",
        "reason_cn": "喜歡心理戰",
        "type": 2
    },
    {
        "reason_en": "Love competition and pursuit of victory",
        "reason_cn": "喜歡競賽、追求勝利",
        "type": 9
    },
    {
        "reason_en": "Challenge difficulty to break record",
        "reason_cn": "挑戰困難、破紀錄",
        "type": 9
    },
    {
        "reason_en": "Gambling",
        "reason_cn": "博弈",
        "type": 8
    },
    {
        "reason_en": "Love to interact with family and friends to improve relationship",
        "reason_cn": "喜歡與親友互動，增加感情",
        "type": 4
    },
    {
        "reason_en": "Enjoy playing layout, strategy, attack and defense",
        "reason_cn": "喜歡佈局、謀略、攻防",
        "type": 1
    },
    {
        "reason_en": "Love logic deduction",
        "reason_cn": "喜歡推理",
        "type": 1
    },
    {
        "reason_en": "Love to collect game types, points and different games",
        "reason_cn": "喜歡累積自己玩過的遊戲種類、積分、收藏不同的遊戲",
        "type": 7
    },
    {
        "reason_en": "Enjoy teamwork",
        "reason_cn": "喜歡團隊合作",
        "type": 4
    },
    {
        "reason_en": "Love game of deliberation or persuasion",
        "reason_cn": "喜歡商議或說服他人的遊戲",
        "type": 10
    },
    {
        "reason_en": "A refreshing sense of thrill and happiness",
        "reason_cn": "很歡樂、很刺激",
        "type": 5
    },
    {
        "reason_en": "Enjoy hands-on games",
        "reason_cn": "喜歡動手的遊戲",
        "type": 6
    },
    {
        "reason_en": "Instruct others",
        "reason_cn": "指導他人",
        "type": 9
    },
    {
        "reason_en": "Physical and mental health",
        "reason_cn": "身心健康",
        "type": 12
    },
    {
        "reason_en": "Prevent brain degeneration",
        "reason_cn": "預防大腦退化",
        "type": 12
    },
    {
        "reason_en": "Train the reactions to things and improve memory",
        "reason_cn": "訓練反應或增加記憶力",
        "type": 1
    },
    {
        "reason_en": "Enjoy peace of mind",
        "reason_cn": "喜歡靜心的感覺",
        "type": 2
    },
    {
        "reason_en": "Accompany others to play games",
        "reason_cn": "陪伴他人遊戲",
        "type": 11
    },
    {
        "reason_en": "Able to have a small talk and make jokes (with each other) during the processes of games or activities",
        "reason_cn": "遊戲或活動過程可以聊天、鬥嘴",
        "type": 10
    }
]
    },
    {
        "id": 4,
        "name_cn": "閱讀",
        "description_cn": "閱讀/看書\r",
        "name_en": "Reading",
        "description_en": "Reading , Reading books",
        "reason": [
    {
        "reason_en": "Love categorizing books",
        "reason_cn": "喜歡把書分類整理",
        "type": 7
    },
    {
        "reason_en": "Enjoy being free from interaction with others",
        "reason_cn": "喜歡不需要跟人互動",
        "type": [6, 7]
    },
    {
        "reason_en": "Settle thoughts and emotions, enjoy being alone",
        "reason_cn": "沉澱思緒，享受獨處。",
        "type": 2
    },
    {
        "reason_en": "Knowledge is power, self-enhancement",
        "reason_cn": "知識就是力量，自我提升",
        "type": 9
    },
    {
        "reason_en": "More information for persuasion",
        "reason_cn": "增加資訊可以說服他人",
        "type": 10
    },
    {
        "reason_en": "Love contemplating",
        "reason_cn": "喜歡動腦思考",
        "type": 1
    },
    {
        "reason_en": "Actively recommending good articles or books to others",
        "reason_cn": "主動推薦好文給他人",
        "type": 10
    },
    {
        "reason_en": "Love reading books with desire to learn everything",
        "reason_cn": "很愛看書，什麼都想學習",
        "type": 1
    },
    {
        "reason_en": "Learning knowledge or language",
        "reason_cn": "學習知識或語言",
        "type": 1
    },
    {
        "reason_en": "Drama novels, humanities biography, historical records",
        "reason_cn": "劇情小說類、人文傳記、歷史紀錄",
        "type": 4
    },
    {
        "reason_en": "Textbooks, reference books, professional books",
        "reason_cn": "教科書、參考書、專業書籍類",
        "type": 1
    },
    {
        "reason_en": "Prose, poetry, literature, design, creation, arts",
        "reason_cn": "散文、詩集、文學、設計、創作、藝術類",
        "type": 3
    },
    {
        "reason_en": "Detective, reasoning, science",
        "reason_cn": "偵探、推理、科學類",
        "type": 1
    },
    {
        "reason_en": "Religion, philosophy, psychology, spirituality",
        "reason_cn": "宗教、哲學、心理、靈性類",
        "type": 2
    },
    {
        "reason_en": "Romance novels; horror, thriller",
        "reason_cn": "言情小說；恐怖、驚悚類",
        "type": 5
    },
    {
        "reason_en": "Science fiction, martial arts, fantasy",
        "reason_cn": "科幻、武俠、奇幻類",
        "type": 2
    },
    {
        "reason_en": "Entertainment, travel, foods, makeups",
        "reason_cn": "娛樂、旅遊、美食、彩妝類",
        "type": 5
    },
    {
        "reason_en": "Plants & animals, environmental protection, ecology",
        "reason_cn": "動植物、環保、生態類",
        "type": 12
    },
    {
        "reason_en": "Financial management, business, finance, marketing, entrepreneurship",
        "reason_cn": "理財、商業、財經、行銷、創業類",
        "type": 8
    },
    {
        "reason_en": "Handbooks, stationery application",
        "reason_cn": "手帳、文具運用類",
        "type": 7
    },
    {
        "reason_en": "Heroes, leadership and management",
        "reason_cn": "英雄、領袖、管理類類",
        "type": 9
    },
    {
        "reason_en": "Health and wellness",
        "reason_cn": "健康、養生類",
        "type": 12
    },
    {
        "reason_en": "Contemplating the author s intention",
        "reason_cn": "思考作者的用意",
        "type": [3, 4]
    },
    {
        "reason_en": "Experiencing others` world, life and feelings",
        "reason_cn": "體驗他人的世界、生活、感受",
        "type": 4
    },
    {
        "reason_en": "Allow imagination while reading books",
        "reason_cn": "看書可以有想像空間",
        "type": [2, 3]
    },
    {
        "reason_en": "Write reading experience and book review",
        "reason_cn": "書寫讀書心得、寫書評",
        "type": [2, 3]
    },
    {
        "reason_en": "Research the plot or feature of the work",
        "reason_cn": "研究劇情或作品特色",
        "type": 3
    },
    {
        "reason_en": "Reference book to follow on hands-on work or providing practical information",
        "reason_cn": "工具書，可以跟著動手做，或實用的訊息",
        "type": 6
    },
    {
        "reason_en": "Love underlining important points and taking notes while reading",
        "reason_cn": "喜歡閱讀時劃線、做筆記",
        "type": 7
    },
    {
        "reason_en": "Enhance one’s inner-cultivation",
        "reason_cn": "提升內在涵養，修身養性",
        "type": [2, 12]
    }
]
    },
    {
        "id": 5,
        "name_cn": "宗教",
        "description_cn": "宗教活動",
        "name_en": "Religion",
        "description_en": "Religious activities",
        "reason": [
    {
        "reason_en": "Contemplate abstract meaning and get the insight of the religious doctrine",
        "reason_cn": "思考抽象的意義、頓悟教義",
        "type": 2
    },
    {
        "reason_en": "In pursuit of Self growth and inner dialogue",
        "reason_cn": "追求自我成長、自我對話",
        "type": 2
    },
    {
        "reason_en": "In pursuit of faith, spiritual support and inner peace",
        "reason_cn": "追求內在信仰、心靈依靠、內心平靜",
        "type": 2
    },
    {
        "reason_en": "In pursuit of the balance among physical, mental and spiritual; self-cultivation",
        "reason_cn": "追求身心靈平衡、修身養性",
        "type": 2
    },
    {
        "reason_en": "Settle thoughts, enjoy being alone",
        "reason_cn": "沉澱思緒、享受獨處",
        "type": 2
    },
    {
        "reason_en": "Mutual support and mutual objective or sense of belonging",
        "reason_cn": "與他人互相支持，有共同目標或歸屬感",
        "type": 4
    },
    {
        "reason_en": "Helping others and be volunteer",
        "reason_cn": "幫助他人、做志工",
        "type": 4
    },
    {
        "reason_en": "Promoting religious doctrine",
        "reason_cn": "宣傳教義",
        "type": [2, 4]
    },
    {
        "reason_en": "Serve the public",
        "reason_cn": "服務大眾",
        "type": 11
    },
    {
        "reason_en": "Eliminate karma, strongly believe in destiny",
        "reason_cn": "消業障、天命如此",
        "type": 2
    },
    {
        "reason_en": "Love simple routine life",
        "reason_cn": "喜歡簡單規律的生活",
        "type": 7
    },
    {
        "reason_en": "In pursuit of world peace and love",
        "reason_cn": "追求世界大愛、和平",
        "type": 12
    },
    {
        "reason_en": "Be a religious leader or management",
        "reason_cn": "成為宗教領導人或幹部",
        "type": 9
    },
    {
        "reason_en": "Love the crowd",
        "reason_cn": "喜歡熱鬧",
        "type": 5
    },
    {
        "reason_en": "Enjoy drawing fortune stick or seeking fortune-telling",
        "reason_cn": "喜歡求籤、算命",
        "type": 2
    },
    {
        "reason_en": "Studying religious doctrine and understanding the meaning",
        "reason_cn": "鑽研宗教教義、經典之深義",
        "type": 1
    },
    {
        "reason_en": "Persuading others to join the faith",
        "reason_cn": "說服他人加入信仰",
        "type": 10
    },
    {
        "reason_en": "Dedicate to God. Worship Gods.",
        "reason_cn": "奉獻服事神；供奉神明",
        "type": 11
    },
    {
        "reason_en": "Feel calm while writing and reciting scriptures",
        "reason_cn": "抄寫經文，重複朗誦很平靜",
        "type": 7
    },
    {
        "reason_en": "Love repeating rituals (with the same procedures)",
        "reason_cn": "喜歡固定、重複的儀式",
        "type": 6
    },
    {
        "reason_en": "Work hard to reach the harmony of the universe and return to all livings.",
        "reason_cn": "盡力於萬物的和諧、迴向眾生",
        "type": 12
    },
    {
        "reason_en": "Pray for health",
        "reason_cn": "祈求健康",
        "type": 12
    },
    {
        "reason_en": "Pray for richness and prosperity",
        "reason_cn": "祈求富貴",
        "type": 8
    },
    {
        "reason_en": "Expand social connections, get to know people with the same preferences",
        "reason_cn": "拓展人脈，結識同好",
        "type": 10
    }
]
    },
    {
        "id": 6,
        "name_cn": "身心靈\r",
        "description_cn": "瑜珈、靜心、冥想、內觀、慢活、潛能開發、心靈療癒、花精、書寫、自我探索、身心靈活動",
        "name_en": "Body and spirit",
        "description_en": "yoga, meditation, self-observation, LOHAS, potential development, spiritual healing, flower essence, writing, self-exploration, Bodily and spiritual activities\r",
        "reason": [
    {
        "reason_en": "Contemplate the abstract meanings; engage in religious or spiritual activities",
        "reason_cn": "思考抽象的意義，宗教或靈性的活動",
        "type": 2
    },
    {
        "reason_en": "In pursuit of inner growth and inner dialogue",
        "reason_cn": "追求內在成長、自我對話",
        "type": 2
    },
    {
        "reason_en": "Express emotions and gain peace of mind to be kept on record",
        "reason_cn": "抒發情感、讓心情平靜、記錄心情",
        "type": 2
    },
    {
        "reason_en": "In pursuit of balance among physical, mental and spiritual; self-cultivation",
        "reason_cn": "追求身心靈平衡、修身養性",
        "type": 2
    },
    {
        "reason_en": "Settle thoughts, enjoy being alone",
        "reason_cn": "沉澱思緒、享受獨處",
        "type": 2
    },
    {
        "reason_en": "Sculpture body shape and maintain the beauty",
        "reason_cn": "雕塑體態、維持美感",
        "type": 3
    },
    {
        "reason_en": "Love to physically work body, interact with my own body",
        "reason_cn": "喜歡活動身體，和身體對話",
        "type": [2, 5]
    },
    {
        "reason_en": "In pursuit of quality life",
        "reason_cn": "追求生活品質",
        "type": 5
    },
    {
        "reason_en": "Develop the potential",
        "reason_cn": "開發潛能",
        "type": 2
    },
    {
        "reason_en": "Physical and mental health",
        "reason_cn": "身心健康",
        "type": 12
    },
    {
        "reason_en": "In search of meaning of life and answer to difficult questions of life",
        "reason_cn": "探尋人生意義，尋求解答難題",
        "type": 2
    },
    {
        "reason_en": "Interact with plants or animals in the nature",
        "reason_cn": "與大自然或動、植物互動",
        "type": 12
    },
    {
        "reason_en": "Help others",
        "reason_cn": "幫助他人",
        "type": 4
    },
    {
        "reason_en": "Get connection and sensation to the universe and the highest existence",
        "reason_cn": "和宇宙、最高的存在連結感應",
        "type": [2, 4]
    },
    {
        "reason_en": "Collect a variety of flower essences, essence oils or related products",
        "reason_cn": "蒐集各種花精、精油或相關產品",
        "type": 7
    },
    {
        "reason_en": "Recommend or share useful methods of self-care to others",
        "reason_cn": "推薦或分享有用的自我照顧方法",
        "type": 10
    },
    {
        "reason_en": "Research in different ways of enhancing body and spirit",
        "reason_cn": "鑽研各式提升身心靈的方法",
        "type": 1
    },
    {
        "reason_en": "Make activities of body and spirit as everyday routines",
        "reason_cn": "讓身心靈活動成為生活習慣",
        "type": 6
    },
    {
        "reason_en": "Industry of body and spirit is profitable",
        "reason_cn": "身心靈產業有商機",
        "type": 8
    },
    {
        "reason_en": "Be the master of my own mind and spirit",
        "reason_cn": "做自己心靈的主人",
        "type": [2, 9]
    },
    {
        "reason_en": "Have control of own body and spirit can change others",
        "reason_cn": "掌握身心靈可以改變別人",
        "type": 9
    }
]
    },
    {
        "id": 7,
        "name_cn": "運勢\r",
        "description_cn": "星座/風水/命理/塔羅牌\r",
        "name_en": "The Fortune",
        "description_en": "Astrology, Feng Shui, Fortune-telling, Tarot cards",
        "reason": [
    {
        "reason_en": "Contemplate the abstract meanings; engage in religious or spiritual activities",
        "reason_cn": "思考抽象的意義，宗教或靈性的活動",
        "type": 2
    },
    {
        "reason_en": "Believe in nature or the rules of cosmos",
        "reason_cn": "相信大自然、天行運轉的法則",
        "type": 2
    },
    {
        "reason_en": "Improve health",
        "reason_cn": "改善健康",
        "type": 12
    },
    {
        "reason_en": "Improve the health and the fortune of family and friends",
        "reason_cn": "改善親友的健康或運勢",
        "type": 4
    },
    {
        "reason_en": "Faith and belief",
        "reason_cn": "信仰、寄託",
        "type": 2
    },
    {
        "reason_en": "Change the self’s fate and know the fortune",
        "reason_cn": "改變自己的命運、了解運勢",
        "type": 2
    },
    {
        "reason_en": "Provide recommendation and instruct others",
        "reason_cn": "提供他人建議，指導他人",
        "type": 9
    },
    {
        "reason_en": "Learn the ancient wisdom",
        "reason_cn": "學習古人的智慧",
        "type": 1
    },
    {
        "reason_en": "In search of meaning of life and answer to difficult questions of life",
        "reason_cn": "探詢人生意義，尋求解答難題",
        "type": 2
    },
    {
        "reason_en": "Self-exploration",
        "reason_cn": "喜歡自我探索",
        "type": 2
    },
    {
        "reason_en": "Interesting and fun",
        "reason_cn": "有趣、好玩",
        "type": 5
    },
    {
        "reason_en": "Increase the topic of conversation with family and friends",
        "reason_cn": "增加親友的話題",
        "type": [4, 10]
    },
    {
        "reason_en": "Can serve others and provide with useful information",
        "reason_cn": "可以服務他人，提供他人有用的資訊",
        "type": 11
    },
    {
        "reason_en": "Improve the monetary fortune",
        "reason_cn": "增加財運",
        "type": 8
    },
    {
        "reason_en": "Improve influential power, career as an official and social status",
        "reason_cn": "增加權勢、官運、地位",
        "type": 9
    },
    {
        "reason_en": "Actively recommending good information to others",
        "reason_cn": "主動推薦資訊給其他人",
        "type": 10
    },
    {
        "reason_en": "Study of the expertise and knowledge in this field",
        "reason_cn": "鑽研此領域的專門知識",
        "type": 1
    },
    {
        "reason_en": "Apply the knowledge to create business opportunity",
        "reason_cn": "將所學創造市場商機",
        "type": 8
    },
    {
        "reason_en": "Persuade others to do something to get good fortunes",
        "reason_cn": "說服他人改運",
        "type": 10
    },
    {
        "reason_en": "Fortune Telling or auguring for others",
        "reason_cn": "幫別人算命、占卜",
        "type": 11
    }
]
    },
    {
        "id": 8,
        "name_cn": "音樂\r",
        "description_cn": "唱歌/演奏樂器/玩音樂\r",
        "name_en": "Musics",
        "description_en": "Singing/Playing instruments/Musics",
        "reason": [
    {
        "reason_en": "Enjoy participating in musical activities",
        "reason_cn": "喜歡音樂活動",
        "type": 3
    },
    {
        "reason_en": "Love singing",
        "reason_cn": "喜歡唱歌",
        "type": 3
    },
    {
        "reason_en": "Love to create",
        "reason_cn": "喜歡創作",
        "type": 3
    },
    {
        "reason_en": "Love playing instruments",
        "reason_cn": "喜歡演奏",
        "type": 3
    },
    {
        "reason_en": "Love auditory, lighting and visual stimulations",
        "reason_cn": "喜歡聲音、燈光、影像的刺激",
        "type": 5
    },
    {
        "reason_en": "Love popular elements",
        "reason_cn": "喜歡流行元素",
        "type": 8
    },
    {
        "reason_en": "Pay attention to business opportunity",
        "reason_cn": "留意商機",
        "type": 8
    },
    {
        "reason_en": "Love the attention and appraisal",
        "reason_cn": "喜歡被注意、被誇讚",
        "type": 9
    },
    {
        "reason_en": "Enjoy hands-on operation/playing with a sense of accomplishment",
        "reason_cn": "喜歡動手操作/彈奏，有成就感",
        "type": 6
    },
    {
        "reason_en": "Express emotions and gain peace of mind",
        "reason_cn": "抒發情感、讓心情平靜",
        "type": 2
    },
    {
        "reason_en": "Spiritual foods; self-cultivation",
        "reason_cn": "心靈糧食、修身養性",
        "type": [2, 12]
    },
    {
        "reason_en": "Enjoy communicating and sharing happiness with others",
        "reason_cn": "喜歡和別他人交流、分享或同樂",
        "type": 4
    },
    {
        "reason_en": "Enjoy the stage or performance opportunities",
        "reason_cn": "喜歡有舞台或表現機會",
        "type": 9
    },
    {
        "reason_en": "Bringing happiness to others",
        "reason_cn": "帶給別人愉快感",
        "type": 11
    },
    {
        "reason_en": "Relaxed, unrestrained and pressure relived",
        "reason_cn": "暢快、奔放、紓壓",
        "type": 5
    },
    {
        "reason_en": "Enjoy teamwork or a sense of belonging",
        "reason_cn": "喜歡團隊合作，或有歸屬感",
        "type": 4
    },
    {
        "reason_en": "Love the crowd",
        "reason_cn": "喜歡熱鬧",
        "type": 5
    },
    {
        "reason_en": "Study the music theory",
        "reason_cn": "研究樂理",
        "type": 1
    },
    {
        "reason_en": "Facilitate physical and mental health",
        "reason_cn": "促進身心健康",
        "type": 12
    },
    {
        "reason_en": "Enjoy song listening (please referred to “music listening” section)",
        "reason_cn": "喜歡聽音樂(請參考「聽歌」項目)",
        "type": 0,
        "card_id_redirection": 49,
    }
]
    },
    {
        "id": 9,
        "name_cn": "藝文",
        "description_cn": "欣賞藝文活動/\r\n看藝術展/\r\n逛文創園區/逛老街/尋訪古蹟\r\n",
        "name_en": "Arts & Culture",
        "description_en": "Appreciation of arts and culture activities/Seeing art exhibitions/Visiting cultural and creative park/Visiting historical heritage\r",
        "reason": [
            {
                "reason_en": "Enjoy participating in arts & cultural activities",
                "reason_cn": "喜歡藝文活動",
                "type": 3
            },
            {
                "reason_en": "Love to create and design",
                "reason_cn": "喜歡創作、設計",
                "type": 3
            },
            {
                "reason_en": "Enjoy beautiful things",
                "reason_cn": "喜歡美的事物",
                "type": 3
            },
            {
                "reason_en": "Love exhibitions",
                "reason_cn": "喜歡展場",
                "type": 8
            },
            {
                "reason_en": "Pay attention to business opportunity",
                "reason_cn": "留意商機",
                "type": 8
            },
            {
                "reason_en": "Spiritual foods; self-cultivation",
                "reason_cn": "心靈糧食、修身養性",
                "type": [2, 12]
            },
            {
                "reason_en": "Enjoy communicating and sharing with others",
                "reason_cn": "喜歡和他人交流分享",
                "type": 4
            },
            {
                "reason_en": "Recommend good exhibitions or works to family and friends",
                "reason_cn": "推薦好的展場或作品給親友",
                "type": 10
            },
            {
                "reason_en": "Enjoy the story behind creation",
                "reason_cn": "喜歡聽創作背後的故事",
                "type": 3
            },
            {
                "reason_en": "Search the inspiration of creating",
                "reason_cn": "尋找創作的靈感",
                "type": 3
            },
            {
                "reason_en": "Enjoy admiring creative works",
                "reason_cn": "喜歡鑑賞作品",
                "type": 3
            },
            {
                "reason_en": "Acquiring new knowledge",
                "reason_cn": "吸收新知",
                "type": 1
            },
            {
                "reason_en": "Love things of texture",
                "reason_cn": "喜歡有質感的物品",
                "type": 3
            },
            {
                "reason_en": "Love to integrate culture-related concepts",
                "reason_cn": "喜歡融合文化的概念",
                "type": 3
            },
            {
                "reason_en": "Enjoy looking for interesting things",
                "reason_cn": "喜歡觀看新奇有趣的物品",
                "type": 5
            },
            {
                "reason_en": "Love art and cultural activities connecting to community or natural",
                "reason_cn": "喜歡結合社區或大自然的藝文活動",
                "type": 12
            },
            {
                "reason_en": "Love cultural creative stationary",
                "reason_cn": "喜歡文創的文具用品",
                "type": 7
            },
            {
                "reason_en": "Love to search inspirations for hand-crafting",
                "reason_cn": "喜歡尋找手作類的靈感",
                "type": 6
            },
            {
                "reason_en": "Love the inheritance of tradition arts",
                "reason_cn": "喜歡傳統藝術的傳承感",
                "type": [3, 6]
            }
        ]
    },
    {
        "id": 10,
        "name_cn": "畫畫",
        "description_cn": "繪畫/電腦繪圖/插畫/塗鴉",
        "name_en": "Drawing",
        "description_en": "Drawing/Computer graphics/illustration/scribble\r",
        "reason": [
            {
                "reason_en": "Painting, illustration, graffiti, computer graphics",
                "reason_cn": "繪畫、插畫、塗鴉、電繪",
                "type": 3
            },
            {
                "reason_en": "In pursuit of mental peace",
                "reason_cn": "追求心情平靜",
                "type": 2
            },
            {
                "reason_en": "Mimic & imitation",
                "reason_cn": "臨摹、仿畫",
                "type": 7
            },
            {
                "reason_en": "Love to create and design",
                "reason_cn": "喜歡創作、設計",
                "type": 3
            },
            {
                "reason_en": "Enjoy using arts to relieve or express emotions",
                "reason_cn": "喜歡用藝術抒發或表現情感",
                "type": [2, 3]
            },
            {
                "reason_en": "Random drawing to kill time",
                "reason_cn": "隨性亂畫、打發時間",
                "type": 5
            },
            {
                "reason_en": "Arts for beautify or decorate objects",
                "reason_cn": "用繪畫來美化或裝飾物品",
                "type": 7
            },
            {
                "reason_en": "Love to record or change mood",
                "reason_cn": "喜歡記錄或轉換心情",
                "type": 2
            },
            {
                "reason_en": "In pursuit of attention and affirmation of personal artworks",
                "reason_cn": "追求作品能被關注、肯定",
                "type": 9
            },
            {
                "reason_en": "Prefer neatness",
                "reason_cn": "要求工整",
                "type": 7
            },
            {
                "reason_en": "Enjoy beautiful things",
                "reason_cn": "喜歡美的事物",
                "type": 3
            },
            {
                "reason_en": "Love to instruct others",
                "reason_cn": "指導別人",
                "type": 9
            },
            {
                "reason_en": "Communicate and discuss with peers of same interest",
                "reason_cn": "和同好交流、切磋",
                "type": 4
            },
            {
                "reason_en": "Give the works to others as gifts",
                "reason_cn": "把作品贈送給他人",
                "type": 11
            },
            {
                "reason_en": "Practical artworks",
                "reason_cn": "作品很實用",
                "type": 6
            },
            {
                "reason_en": "Conveying ideology to affect others",
                "reason_cn": "傳達理念、影響他人",
                "type": [4, 9]
            },
            {
                "reason_en": "Creative artworks with business purpose or market value",
                "reason_cn": "創作符合商業用途，或有市場性的作品",
                "type": 8
            },
            {
                "reason_en": "In pursuit of perfection",
                "reason_cn": "追求完美",
                "type": [3, 9]
            },
            {
                "reason_en": "In study of art history",
                "reason_cn": "研究藝術史",
                "type": 1
            },
            {
                "reason_en": "Study of the expertise and techniques in this field",
                "reason_cn": "鑽研此領域的專門知識與技法",
                "type": 1
            },
            {
                "reason_en": "Love to paint in the nature",
                "reason_cn": "喜歡到大自然寫生",
                "type": 12
            },
            {
                "reason_en": "Self-cultivate",
                "reason_cn": "修身養性",
                "type": [2, 12]
            },
            {
                "reason_en": "Able to express to the utmost and freely",
                "reason_cn": "可以盡情揮灑",
                "type": 3
            },
            {
                "reason_en": "Love to create harmonious colors and views",
                "reason_cn": "喜歡創作出和諧的色彩、畫面",
                "type": 3
            },
            {
                "reason_en": "Enjoy the ideas emerging from the process of creation",
                "reason_cn": "享受在產出作品過程中的靈感",
                "type": 3
            },
            {
                "reason_en": "Pursue the self-fulfillment of own creation",
                "reason_cn": "追求作品的自我滿意度",
                "type": 3
            },
            {
                "reason_en": "Love to visualize the abstract concepts",
                "reason_cn": "喜歡把抽象概念視覺化",
                "type": [3, 7]
            }
        ]
    },
    {
        "id": 11,
        "name_cn": "拍攝\r\n",
        "description_cn": "拍照/攝影",
        "name_en": "Photographing & filming",
        "description_en": "Photographing/Filming",
        "reason": [
            {
                "reason_en": "Studying filming techniques",
                "reason_cn": "鑽研攝影技巧",
                "type": 1
            },
            {
                "reason_en": "Enjoy capturing view of beautiful things",
                "reason_cn": "喜歡拍美的景物",
                "type": 3
            },
            {
                "reason_en": "Love the changes in light and shadow",
                "reason_cn": "喜歡光影的變化",
                "type": 3
            },
            {
                "reason_en": "Love selfies",
                "reason_cn": "喜歡自拍",
                "type": 9
            },
            {
                "reason_en": "Love operating filming equipment",
                "reason_cn": "喜歡操作攝影器材",
                "type": 6
            },
            {
                "reason_en": "Life account",
                "reason_cn": "記錄生活",
                "type": 7
            },
            {
                "reason_en": "As a way of recollection and memory",
                "reason_cn": "作為一種回憶與紀念",
                "type": [2, 4]
            },
            {
                "reason_en": "Love producing films",
                "reason_cn": "喜歡製作影片",
                "type": 3
            },
            {
                "reason_en": "Works for commercial purpose",
                "reason_cn": "作品可以做為商業用途",
                "type": 8
            },
            {
                "reason_en": "Earn money or reward with works",
                "reason_cn": "作品可以賺錢、賺獎金",
                "type": 8
            },
            {
                "reason_en": "Love creating a graphical presentation",
                "reason_cn": "喜歡創造畫面",
                "type": 3
            },
            {
                "reason_en": "Love storytelling through graphical display",
                "reason_cn": "喜歡用畫面說故事",
                "type": 3
            },
            {
                "reason_en": "Expecting appraisal and affirmation",
                "reason_cn": "期待被稱讚、肯定",
                "type": 9
            },
            {
                "reason_en": "Able to entertain others",
                "reason_cn": "可以帶給他人娛樂效果",
                "type": 11
            },
            {
                "reason_en": "Love the video effects",
                "reason_cn": "喜歡影視效果",
                "type": 3
            },
            {
                "reason_en": "Love learning and studying hardware and software",
                "reason_cn": "喜歡學習和研究軟硬體",
                "type": 1
            },
            {
                "reason_en": "Seeking fame and great click through rate",
                "reason_cn": "希望有知名度、點閱率",
                "type": 9
            },
            {
                "reason_en": "Sharing works, facilitating discussions and connecting each other",
                "reason_cn": "分享作品，促進討論與聯絡感情",
                "type": 4
            },
            {
                "reason_en": "Help take photos for others",
                "reason_cn": "幫他人拍照",
                "type": 11
            },
            {
                "reason_en": "Express the beauty of the nature or animals and plants",
                "reason_cn": "展現大自然或動植物的美",
                "type": 12
            },
            {
                "reason_en": "Increasing topics to talk to others",
                "reason_cn": "增加和他人的話題",
                "type": 10
            },
            {
                "reason_en": "Exchange works, expand social connections, get to know people with same preferences",
                "reason_cn": "交流作品，拓展人脈、結交同好",
                "type": 10
            }
        ]
    },
    {
        "id": 12,
        "name_cn": "文字",
        "description_cn": "寫作/書法/練字",
        "name_en": "Words",
        "description_en": "Writing/Calligraphy/practice writing\r",
        "reason": [
            {
                "reason_en": "Writing",
                "reason_cn": "寫作",
                "type": 3
            },
            {
                "reason_en": "In pursuit of beauty in structures and writing strokes",
                "reason_cn": "追求結構、筆法…等美感",
                "type": 3
            },
            {
                "reason_en": "Express emotions and gain peace of mind",
                "reason_cn": "抒發情感、讓心情平靜",
                "type": 2
            },
            {
                "reason_en": "Love to create and design",
                "reason_cn": "喜歡創作、設計",
                "type": 3
            },
            {
                "reason_en": "In pursuit of mainstream popularity",
                "reason_cn": "追求流行",
                "type": 5
            },
            {
                "reason_en": "Love to script",
                "reason_cn": "喜歡編寫劇情",
                "type": 3
            },
            {
                "reason_en": "Use of words or calligraphy for object decoration",
                "reason_cn": "用文字或書法美化或裝飾物品",
                "type": 7
            },
            {
                "reason_en": "Practicing with calligraphy copybook",
                "reason_cn": "臨摹字帖",
                "type": 7
            },
            {
                "reason_en": "Love to record or change mood",
                "reason_cn": "喜歡記錄或轉換心情",
                "type": 2
            },
            {
                "reason_en": "In pursuit of attention and affirmation of personal calligraphy works",
                "reason_cn": "作品、筆跡能被關注、肯定",
                "type": 9
            },
            {
                "reason_en": "Prefer neatness",
                "reason_cn": "要求工整",
                "type": 7
            },
            {
                "reason_en": "Enjoy beautiful things",
                "reason_cn": "喜歡美的事物",
                "type": 3
            },
            {
                "reason_en": "Love to instruct others",
                "reason_cn": "指導別人",
                "type": 9
            },
            {
                "reason_en": "Communicate and discuss with peers of same interest",
                "reason_cn": "和同好交流、切磋",
                "type": 4
            },
            {
                "reason_en": "Give the works to others as gifts",
                "reason_cn": "把作品贈送給他人",
                "type": 11
            },
            {
                "reason_en": "Practical artworks",
                "reason_cn": "作品很實用",
                "type": 6
            },
            {
                "reason_en": "Conveying ideology to affect others",
                "reason_cn": "傳達理念、影響他人",
                "type": [4, 9]
            },
            {
                "reason_en": "Creative artworks with business purpose or market value",
                "reason_cn": "創作符合商業用途，或有市場性的作品",
                "type": 8
            },
            {
                "reason_en": "In pursuit of perfection",
                "reason_cn": "追求完美",
                "type": [3, 9]
            },
            {
                "reason_en": "Study of the expertise and techniques in this field",
                "reason_cn": "鑽研此領域的專門知識與技法",
                "type": 1
            },
            {
                "reason_en": "Self-cultivate",
                "reason_cn": "修身養性",
                "type": [2, 12]
            },
            {
                "reason_en": "Love to practice repeatedly",
                "reason_cn": "喜歡反覆練習",
                "type": 6
            }
        ]
    },
    {
        "id": 13,
        "name_cn": "扮演",
        "description_cn": "Cosplay角色扮演/搞笑/模仿/手偶",
        "name_en": "Role playing",
        "description_en": "Cosplay, role playing ,Comedy(make fun), Imitation, hand puppet\r",
        "reason": [
            {
                "reason_en": "Love acting",
                "reason_cn": "喜歡表演",
                "type": 3
            },
            {
                "reason_en": "Enjoy imagining the mood of different characters",
                "reason_cn": "喜歡想像不同角色的心境",
                "type": 2
            },
            {
                "reason_en": "Enjoy experiencing the stories of different characters",
                "reason_cn": "喜歡體驗不同角色的故事",
                "type": 2
            },
            {
                "reason_en": "Love to create plot or dialogue",
                "reason_cn": "喜歡創作劇情或對話",
                "type": 3
            },
            {
                "reason_en": "Enjoy entertaining others",
                "reason_cn": "喜歡娛樂大家",
                "type": 11
            },
            {
                "reason_en": "Love to show off and be the center of attention",
                "reason_cn": "喜歡表現自己、被人們注意",
                "type": 9
            },
            {
                "reason_en": "Enjoy being the important character",
                "reason_cn": "喜歡當核心人物",
                "type": 9
            },
            {
                "reason_en": "Enjoy producing and revising costumes or props",
                "reason_cn": "喜歡製作、改造道具或服裝",
                "type": 6
            },
            {
                "reason_en": "Love the crowd and happiness",
                "reason_cn": "喜歡熱鬧、歡笑",
                "type": 5
            },
            {
                "reason_en": "Love changes and creativity",
                "reason_cn": "喜歡變化、創意",
                "type": 3
            },
            {
                "reason_en": "Enjoy gathering with crowds",
                "reason_cn": "喜歡大家熱鬧在一起",
                "type": [4, 10]
            },
            {
                "reason_en": "Take body as the prop",
                "reason_cn": "身體就是道具",
                "type": [3, 5]
            },
            {
                "reason_en": "Create business opportunity or as a marketing method",
                "reason_cn": "創造商機或作為行銷手法",
                "type": 8
            }
        ]
    },
    {
        "id": 14,
        "name_cn": "表演",
        "description_cn": "跳舞/演戲/展現自我/表演藝術",
        "name_en": "Performance",
        "description_en": "Dance/acting /perform oneself/performance art",
        "reason": [
            {
                "reason_en": "Perform oneself",
                "reason_cn": "展現自我",
                "type": 9
            },
            {
                "reason_en": "Exercise physically and get sweated",
                "reason_cn": "流汗活動身體",
                "type": 5
            },
            {
                "reason_en": "Shape body form, increase appearance attraction",
                "reason_cn": "雕塑體型、提高外表吸引力",
                "type": 5
            },
            {
                "reason_en": "Maintain physical and mental health",
                "reason_cn": "維持身心健康",
                "type": [2, 12]
            },
            {
                "reason_en": "The involvement while acting",
                "reason_cn": "演出角色時的投入感",
                "type": [2, 4]
            },
            {
                "reason_en": "Observe and study how to make performances better",
                "reason_cn": "觀摩、鑽研如何有更好的演出",
                "type": [1, 9]
            },
            {
                "reason_en": "Interact and collaborate with others",
                "reason_cn": "和他人一起互動、合作",
                "type": 4
            },
            {
                "reason_en": "All kinds of performances are creations of art",
                "reason_cn": "各種表演都是藝術創作",
                "type": 3
            },
            {
                "reason_en": "Love to perform on stages (of all kinds)",
                "reason_cn": "喜歡有表現舞台",
                "type": 9
            },
            {
                "reason_en": "Keep practicing and rehearsing constantly and repeatedly",
                "reason_cn": "不斷重覆練習、排練",
                "type": [6, 7]
            },
            {
                "reason_en": "Pursue to make perfect performances",
                "reason_cn": "追求完美的表演",
                "type": 9
            },
            {
                "reason_en": "Get to know people with same preferences or new friends",
                "reason_cn": "認識同好、新朋友",
                "type": [8, 10]
            },
            {
                "reason_en": "Help team make success",
                "reason_cn": "幫助團隊成功",
                "type": 11
            },
            {
                "reason_en": "Organize all the details of rehearsals",
                "reason_cn": "編排預演的各種細節",
                "type": 7
            },
            {
                "reason_en": "Prepare the tools and equipment",
                "reason_cn": "準備道具、器材",
                "type": 6
            },
            {
                "reason_en": "Design the costumes, tools, and scenes for the performance",
                "reason_cn": "設計演出用服裝、道具、佈景",
                "type": 3
            },
            {
                "reason_en": "The rhythms and grooves while performing with music",
                "reason_cn": "演出時搭配音樂的律動感",
                "type": [3, 5]
            },
            {
                "reason_en": "Feel cool, awesome, and be identified by peers",
                "reason_cn": "很酷、很帥、被同儕認同",
                "type": [5, 9]
            },
            {
                "reason_en": "Feel belongingness while being with others",
                "reason_cn": "和大家一起很有歸屬感",
                "type": 4
            },
            {
                "reason_en": "Love competitions, self-challenges",
                "reason_cn": "喜歡競賽、自我挑戰",
                "type": 9
            },
            {
                "reason_en": "Express affects physically, express strengths and beauty",
                "reason_cn": "用肢體表達情感、表達力與美",
                "type": [3, 5]
            }
        ]
    },
    {
        "id": 15,
        "name_cn": "著想",
        "description_cn": "關心/照顧他人/當志工/公益活動",
        "name_en": "Consideration",
        "description_en": "Caring (for others)/Volunteer/Charitable activities",
        "reason": [
            {
                "reason_en": "Caring or helping others",
                "reason_cn": "關心、照顧或幫助他人",
                "type": 4
            },
            {
                "reason_en": "Thinking for others’ needs",
                "reason_cn": "思考他人的需求",
                "type": 4
            },
            {
                "reason_en": "Give full play to self-value",
                "reason_cn": "發揮自我價值",
                "type": 2
            },
            {
                "reason_en": "Willing to provide service",
                "reason_cn": "願意提供服務、付出",
                "type": 11
            },
            {
                "reason_en": "Good at giving advice",
                "reason_cn": "擅長給予建議",
                "type": 9
            },
            {
                "reason_en": "Love to interact with people",
                "reason_cn": "喜歡與人交流互動",
                "type": [4, 10, 11]
            },
            {
                "reason_en": "Religious faith",
                "reason_cn": "宗教信仰",
                "type": 2
            },
            {
                "reason_en": "A sense of belonging",
                "reason_cn": "有歸屬感",
                "type": 4
            },
            {
                "reason_en": "Environmental and conservation issue",
                "reason_cn": "環境、保育議題",
                "type": 12
            },
            {
                "reason_en": "Wish to persuade others to agreement",
                "reason_cn": "希望說服他人認同自己",
                "type": 10
            },
            {
                "reason_en": "Expanding connections",
                "reason_cn": "拓展人脈",
                "type": [8, 10]
            },
            {
                "reason_en": "Promoting physical and mental health",
                "reason_cn": "促進身心健康",
                "type": 12
            },
            {
                "reason_en": "Tend to take actions to express regards to others",
                "reason_cn": "習慣以具體行動來表達關懷",
                "type": 6
            },
            {
                "reason_en": "Resist the injustices in life",
                "reason_cn": "為周遭不公不義的事情抱不平",
                "type": 12
            },
            {
                "reason_en": "Provide feedbacks to the society",
                "reason_cn": "回饋社會",
                "type": 4
            },
            {
                "reason_en": "Find the core of life",
                "reason_cn": "尋找生活重心",
                "type": 7
            },
            {
                "reason_en": "Fulfill enterprise`s responsibility",
                "reason_cn": "盡企業責任",
                "type": 8
            }
        ]
    },
    {
        "id": 16,
        "name_cn": "相聚",
        "description_cn": "聊天/親友聚會/陪伴",
        "name_en": "Gathering",
        "description_en": "Chatting/Family friend gathering/accompany",
        "reason": [
            {
                "reason_en": "Get to know new acquaintance",
                "reason_cn": "喜歡認識新朋友",
                "type": [4, 10]
            },
            {
                "reason_en": "Listen to friends and relatives, understand their inner thoughts, and feelings",
                "reason_cn": "聽親友說心事，知道他人感受或想法",
                "type": 4
            },
            {
                "reason_en": "Observe others’ interaction or conversation",
                "reason_cn": "觀察他人的互動或談話",
                "type": 2
            },
            {
                "reason_en": "Love to influence or instruct others",
                "reason_cn": "喜歡影響或教導他人",
                "type": 4
            },
            {
                "reason_en": "Love to communicate and interact with others",
                "reason_cn": "喜歡和他人交流、互動",
                "type": [4, 10, 11]
            },
            {
                "reason_en": "A sense of belonging",
                "reason_cn": "有歸屬感",
                "type": 4
            },
            {
                "reason_en": "Do not want to be alone",
                "reason_cn": "不想要獨自一人",
                "type": [10, 11]
            },
            {
                "reason_en": "Help to solve problems",
                "reason_cn": "幫忙解決困難",
                "type": [4, 11]
            },
            {
                "reason_en": "Share own feelings",
                "reason_cn": "分享自己的感受",
                "type": 2
            },
            {
                "reason_en": "Host gathering and arrange social activities",
                "reason_cn": "舉辦聚會、安排活動",
                "type": [9, 10]
            },
            {
                "reason_en": "Love and be the focus of attention",
                "reason_cn": "喜歡被注意、當核心人物",
                "type": 9
            },
            {
                "reason_en": "Love to give advice",
                "reason_cn": "喜歡給予建議",
                "type": 9
            },
            {
                "reason_en": "Love to express opinion",
                "reason_cn": "喜歡發表言論",
                "type": [9, 10]
            },
            {
                "reason_en": "Love to give and be kind to relatives and friends",
                "reason_cn": "喜歡付出、對親友好",
                "type": 11
            },
            {
                "reason_en": "Love to make others laugh or please others",
                "reason_cn": "喜歡搞笑逗樂別人，或者取悅他人",
                "type": 11
            },
            {
                "reason_en": "Love to persuade others",
                "reason_cn": "喜歡說服別人",
                "type": 10
            },
            {
                "reason_en": "Love the crowd",
                "reason_cn": "喜歡熱鬧",
                "type": 5
            },
            {
                "reason_en": "Knowledge exchange",
                "reason_cn": "知識交流",
                "type": 1
            },
            {
                "reason_en": "Broaden the knowledge",
                "reason_cn": "增廣見聞",
                "type": 1
            },
            {
                "reason_en": "Stimulating creativity",
                "reason_cn": "激發創意",
                "type": 3
            },
            {
                "reason_en": "Expanding connections",
                "reason_cn": "拓展人脈",
                "type": [8, 10]
            },
            {
                "reason_en": "Communicate emotionally is able to facilitate physical and mental health",
                "reason_cn": "情感交流可以促進身心健康",
                "type": 12
            },
            {
                "reason_en": "Accompany others",
                "reason_cn": "陪伴他人",
                "type": 11
            }
        ]
    },
    {
        "id": 17,
        "name_cn": "家人",
        "description_cn": "家庭活動",
        "name_en": "Family",
        "description_en": "Family activities",
        "reason": [
            {
                "reason_en": "Learn and study something with family",
                "reason_cn": "和家人一起學習、研究某事物",
                "type": 1
            },
            {
                "reason_en": "Engage in artistic and cultural activities with family",
                "reason_cn": "和家人一起從事藝文活動",
                "type": 3
            },
            {
                "reason_en": "Love the feeling of home, with a sense of belonging",
                "reason_cn": "喜歡家的感覺，有歸屬感",
                "type": 4
            },
            {
                "reason_en": "Feel happy being with family",
                "reason_cn": "家人在一起很愉快",
                "type": 4
            },
            {
                "reason_en": "Mutually sharing, caring and supporting each other",
                "reason_cn": "互相分享、關懷、支持",
                "type": 4
            },
            {
                "reason_en": "Pour out one's thoughts or feelings to family",
                "reason_cn": "傾吐自己的心事或感受",
                "type": 2
            },
            {
                "reason_en": "Love the crowd and gathering",
                "reason_cn": "喜歡熱鬧、聚會、聚餐",
                "type": 5
            },
            {
                "reason_en": "Love simple routine life",
                "reason_cn": "喜歡簡單平凡的生活",
                "type": [6, 7]
            },
            {
                "reason_en": "Family is the most important",
                "reason_cn": "家人是最重要的",
                "type": 4
            },
            {
                "reason_en": "Cherish the fate of this life",
                "reason_cn": "珍惜此生的緣份",
                "type": 2
            },
            {
                "reason_en": "Responsibility and obligation to fulfill the role",
                "reason_cn": "盡到角色的責任義務",
                "type": [6, 7]
            },
            {
                "reason_en": "Being valued at home",
                "reason_cn": "在家中很被重視",
                "type": 9
            },
            {
                "reason_en": "Feel relaxed and free",
                "reason_cn": "很放鬆、自在",
                "type": 5
            },
            {
                "reason_en": "Value the health of families and myself",
                "reason_cn": "重視家人和自己的健康",
                "type": 12
            },
            {
                "reason_en": "Enjoy discussing some issues with family",
                "reason_cn": "樂於跟家人辯論某些議題",
                "type": 10
            },
            {
                "reason_en": "Help family solve problems and be a helper",
                "reason_cn": "幫家人解決問題、當小幫手",
                "type": 11
            },
            {
                "reason_en": "Love outdoors and the nature",
                "reason_cn": "喜歡到戶外、大自然走走",
                "type": 12
            },
            {
                "reason_en": "Act to express care to others",
                "reason_cn": "習慣以具體行動來表達關懷",
                "type": 6
            },
            {
                "reason_en": "Take pets as one of the family members",
                "reason_cn": "把寵物當作家人",
                "type": 12
            },
            {
                "reason_en": "Understand and improve family relationships",
                "reason_cn": "理解與改善家人關係",
                "type": 2
            },
            {
                "reason_en": "Take care of family deliberately, remind family of important things",
                "reason_cn": "幫家人瞻前顧後、叮嚀提醒",
                "type": [7, 11]
            }
        ]
    },
    {
        "id": 18,
        "name_cn": "玩車",
        "description_cn": "開車/騎車/改車/車友聚",
        "name_en": "Automobile activities",
        "description_en": "Driving/Riding/Vehicle DIY/Gathering with auto fans",
        "reason": [
            {
                "reason_en": "Go for a ride and enjoy",
                "reason_cn": "吹風、享受",
                "type": 5
            },
            {
                "reason_en": "Love the sensation of fast speed",
                "reason_cn": "喜歡速度感",
                "type": 5
            },
            {
                "reason_en": "Able to travel around",
                "reason_cn": "到處遊玩",
                "type": 5
            },
            {
                "reason_en": "Being alone, free and feeling at peace",
                "reason_cn": "安靜的獨處、自在",
                "type": 2
            },
            {
                "reason_en": "Thrill and adventurous",
                "reason_cn": "刺激、探險",
                "type": 5
            },
            {
                "reason_en": "Challenge self",
                "reason_cn": "挑戰自己",
                "type": 9
            },
            {
                "reason_en": "Symbol of identity and social status",
                "reason_cn": "象徵身分地位",
                "type": 9
            },
            {
                "reason_en": "Enjoy being the center of attention",
                "reason_cn": "喜歡被注目",
                "type": 9
            },
            {
                "reason_en": "Enjoy the sense of in control when driving",
                "reason_cn": "享受駕馭操控感",
                "type": 9
            },
            {
                "reason_en": "Collecting valuable limited-edition cars",
                "reason_cn": "收藏會增值的車種",
                "type": 8
            },
            {
                "reason_en": "Recommending cars or accessories to others",
                "reason_cn": "推薦他人車款或配件",
                "type": 10
            },
            {
                "reason_en": "Mind rest",
                "reason_cn": "沉澱心情",
                "type": 2
            },
            {
                "reason_en": "Sightseeing",
                "reason_cn": "看風景",
                "type": 12
            },
            {
                "reason_en": "Pick up and drop off",
                "reason_cn": "提供接送",
                "type": 11
            },
            {
                "reason_en": "Convenient and practical",
                "reason_cn": "方便、實用",
                "type": 6
            },
            {
                "reason_en": "Love to do car DIYs",
                "reason_cn": "喜歡動手裝修車子",
                "type": 6
            },
            {
                "reason_en": "Love to study about machines, accessories and car types",
                "reason_cn": "喜歡研究機器、零件、車種",
                "type": 1
            },
            {
                "reason_en": "Communicate and interact with others",
                "reason_cn": "和他人交流互動",
                "type": [4, 10, 11]
            },
            {
                "reason_en": "Expanding connections",
                "reason_cn": "拓展人脈",
                "type": [8, 10]
            },
            {
                "reason_en": "Appreciate the appearance, design, and the color-matching",
                "reason_cn": "欣賞外型、設計、配色",
                "type": 3
            },
            {
                "reason_en": "Love maintaining cars with hands-on efforts",
                "reason_cn": "喜歡動手保養車子",
                "type": 6
            }
        ]
    },
    {
        "id": 19,
        "name_cn": "美妝",
        "description_cn": "打扮/保養/SPA\r",
        "name_en": "Cosmetics",
        "description_en": "Grooming/ Care products/SPA",
        "reason": [
            {
                "reason_en": "Paying attention to outer appearance",
                "reason_cn": "重視外表",
                "type": 5
            },
            {
                "reason_en": "Love the environment of beauty saloon",
                "reason_cn": "喜歡美容、美髮的環境",
                "type": 5
            },
            {
                "reason_en": "Love to change hairstyle",
                "reason_cn": "喜歡變換造型",
                "type": 5
            },
            {
                "reason_en": "Enjoy the process, get relaxed",
                "reason_cn": "享受過程、放鬆心情",
                "type": 5
            },
            {
                "reason_en": "Love to dress up and make up by self",
                "reason_cn": "喜歡自己動手化妝、打扮",
                "type": 6
            },
            {
                "reason_en": "Pay attention to the dress style and texture",
                "reason_cn": "重視穿著品味和質感",
                "type": 3
            },
            {
                "reason_en": "Pay attention to the mainstream outfit style",
                "reason_cn": "注重穿搭流行",
                "type": 8
            },
            {
                "reason_en": "Love and be the center of attention",
                "reason_cn": "喜歡被注意",
                "type": 9
            },
            {
                "reason_en": "Love bottles of cosmetics to be well-organized",
                "reason_cn": "喜歡瓶瓶罐罐放整齊",
                "type": 7
            },
            {
                "reason_en": "Investigating the ingredients of cosmetic products",
                "reason_cn": "研究保養品的成分",
                "type": 1
            },
            {
                "reason_en": "Researching the anti-aging and cosmetic scientific method",
                "reason_cn": "鑽研抗老化、美容的科學方法",
                "type": 1
            },
            {
                "reason_en": "Increase the topic of conversation with peers",
                "reason_cn": "增加同儕話題",
                "type": [4, 10]
            },
            {
                "reason_en": "Try different make-ups and outfits",
                "reason_cn": "嘗試不同的妝容與穿搭",
                "type": 5
            },
            {
                "reason_en": "Recommending good brands to family and friends",
                "reason_cn": "推薦親友好用的品牌",
                "type": 10
            },
            {
                "reason_en": "Helping family and friends in dressing up",
                "reason_cn": "替親友打扮或服務",
                "type": 11
            },
            {
                "reason_en": "Love recommending outfit styles to others",
                "reason_cn": "喜歡建議他人如何穿搭打扮",
                "type": 9
            },
            {
                "reason_en": "Recommending good products, outfits or styles to family and friends",
                "reason_cn": "推薦好用的產品、穿搭或方法給親友",
                "type": 10
            },
            {
                "reason_en": "Provide make-ups or outfit styles for family and friends",
                "reason_cn": "提供親友需要的美妝或穿搭",
                "type": 11
            },
            {
                "reason_en": "Show personal style or status",
                "reason_cn": "展現個人風格或地位",
                "type": 9
            },
            {
                "reason_en": "Pay attention to special offers, discounts or business opportunity",
                "reason_cn": "注意優惠、特價或商機",
                "type": 8
            },
            {
                "reason_en": "Value health and natural ingredients",
                "reason_cn": "重視健康、天然成分",
                "type": 12
            },
            {
                "reason_en": "Take care of myself, treat myself kindly",
                "reason_cn": "照顧自己、要對自己好",
                "type": 2
            }
        ]
    },
    {
        "id": 20,
        "name_cn": "逛街",
        "description_cn": "逛街/購物",
        "name_en": "Shopping",
        "description_en": "Shopping",
        "reason": [
            {
                "reason_en": "Love the feeling of spending money",
                "reason_cn": "喜歡花錢的感覺",
                "type": 5
            },
            {
                "reason_en": "Love to browse for variety of commodities",
                "reason_cn": "喜歡觀看各式商品",
                "type": 8
            },
            {
                "reason_en": "Love to interact with family and friends",
                "reason_cn": "喜歡和親友互動",
                "type": [4, 10, 11]
            },
            {
                "reason_en": "Love to research on business marketing methods",
                "reason_cn": "喜歡研究商業行銷方式",
                "type": 8
            },
            {
                "reason_en": "Love to observe the consumer behavior or mentality",
                "reason_cn": "喜歡觀察消費者的行為或心態",
                "type": 8
            },
            {
                "reason_en": "Love to observe the line of movement or setting in the shopping center",
                "reason_cn": "喜歡觀察賣場動線或擺設",
                "type": 8
            },
            {
                "reason_en": "Love to shop for daily and practical goods",
                "reason_cn": "喜歡添購日用品、實用的東西",
                "type": 6
            },
            {
                "reason_en": "Love to list the shopping list.",
                "reason_cn": "喜歡列出購物清單",
                "type": 7
            },
            {
                "reason_en": "Enjoy thinking about CP value (the cost-price ratio)",
                "reason_cn": "喜歡思考CP值(價值與價格的比率)",
                "type": 8
            },
            {
                "reason_en": "Enjoy consuming economically (less expensive)",
                "reason_cn": "喜歡經濟實惠(便宜)",
                "type": [6, 8]
            },
            {
                "reason_en": "Observe business opportunity",
                "reason_cn": "觀察商機",
                "type": 8
            },
            {
                "reason_en": "Notice slight differences in products",
                "reason_cn": "留意產品的細微差異",
                "type": 7
            },
            {
                "reason_en": "Enjoy looking for new and interesting things",
                "reason_cn": "喜歡看新奇、豐富的物品",
                "type": 5
            },
            {
                "reason_en": "Love observing others’ interaction",
                "reason_cn": "喜歡觀察他人互動",
                "type": 2
            },
            {
                "reason_en": "Love handicrafts, creative products, arts and crafts",
                "reason_cn": "喜歡手工藝品、創作小物、文藝品",
                "type": 3
            },
            {
                "reason_en": "Pay attention to fashion, cosmetics, clothing, etc.",
                "reason_cn": "關注時尚、美妝、穿搭…等",
                "type": 5
            },
            {
                "reason_en": "Love planning the order and the objectives of shopping",
                "reason_cn": "喜歡規劃購物順序、目標",
                "type": 7
            },
            {
                "reason_en": "Group shopping",
                "reason_cn": "團購",
                "type": [8, 10]
            },
            {
                "reason_en": "Help family and friends to shopping",
                "reason_cn": "幫親友採購",
                "type": 11
            },
            {
                "reason_en": "Value brands",
                "reason_cn": "重視品牌",
                "type": 5
            },
            {
                "reason_en": "Love the crowd",
                "reason_cn": "喜歡熱鬧的地方",
                "type": 5
            },
            {
                "reason_en": "Love the peddling cry in market",
                "reason_cn": "喜歡聽叫賣的功力",
                "type": 10
            },
            {
                "reason_en": "Love to observe the interaction with staff",
                "reason_cn": "喜歡看服務員的應對",
                "type": 11
            },
            {
                "reason_en": "Enjoy the sense of attention when serviced during shopping",
                "reason_cn": "喜歡消費時被服務、被重視的感覺",
                "type": 9
            },
            {
                "reason_en": "Broaden the knowledge",
                "reason_cn": "增廣見聞",
                "type": 1
            }
        ]
    },
    {
        "id": 21,
        "name_cn": "茶點\r",
        "description_cn": "製作點心、製作飲品、烘焙、泡茶、沖咖啡",
        "name_en": "Snacks",
        "description_en": "Making desserts or drinks, baking, tea making, coffee making",
        "reason": [
            {
                "reason_en": "Value quality of life",
                "reason_cn": "重視生活品質，享受",
                "type": 5
            },
            {
                "reason_en": "Enjoy do things by self",
                "reason_cn": "喜歡動手做",
                "type": 6
            },
            {
                "reason_en": "Research about cooking materials and methods",
                "reason_cn": "研究食材與方法",
                "type": 1
            },
            {
                "reason_en": "Learn all kinds of methods",
                "reason_cn": "學習各式作法",
                "type": 1
            },
            {
                "reason_en": "Love to create",
                "reason_cn": "喜歡創作",
                "type": 3
            },
            {
                "reason_en": "Enjoy providing final products to others",
                "reason_cn": "喜歡將成品提供給他人",
                "type": 11
            },
            {
                "reason_en": "Love being alone, reflecting on self while snacking",
                "reason_cn": "喜歡在享用過程中獨處、沉澱",
                "type": 2
            },
            {
                "reason_en": "Collecting and organizing recipes",
                "reason_cn": "蒐集、整理食譜",
                "type": 7
            },
            {
                "reason_en": "Trying out small business and paying attention to opportunities",
                "reason_cn": "嘗試小生意、留意商機",
                "type": 8
            },
            {
                "reason_en": "Talking and exchanging with family and friends",
                "reason_cn": "與親友聊天、交流",
                "type": [4, 10, 11]
            },
            {
                "reason_en": "Enjoy eating and drinking",
                "reason_cn": "自己喜歡吃吃喝喝",
                "type": 5
            },
            {
                "reason_en": "Foods by self are healthier and safer",
                "reason_cn": "自己做比較健康、天然",
                "type": 12
            },
            {
                "reason_en": "Homemade foods are more economical, able to save money",
                "reason_cn": "自己做比較省錢、經濟實惠",
                "type": 6
            },
            {
                "reason_en": "Practice skills",
                "reason_cn": "練習技術",
                "type": 6
            },
            {
                "reason_en": "In pursuit of perfection in final products",
                "reason_cn": "追求成品的完美",
                "type": 9
            },
            {
                "reason_en": "Display products and the process to gain more attention",
                "reason_cn": "展示成品、過程以求更多關注",
                "type": 9
            },
            {
                "reason_en": "Share online for marketing the food materials and tools",
                "reason_cn": "分享上網以推銷食材、器具",
                "type": 10
            },
            {
                "reason_en": "Share the production process and recipes",
                "reason_cn": "將製作過程、配方公開分享",
                "type": 11
            },
            {
                "reason_en": "Initiate the group purchasing",
                "reason_cn": "開團購",
                "type": [5, 10]
            },
            {
                "reason_en": "Feeling been healed, nurturing body and mind",
                "reason_cn": "覺得療癒、滋養身心",
                "type": [2, 5]
            },
            {
                "reason_en": "A routine of everyday life",
                "reason_cn": "是一種生活習慣",
                "type": [6, 7]
            }
        ]
    },
    {
        "id": 22,
        "name_cn": "運動",
        "description_cn": "運動/健身/極限運動/體能競賽",
        "name_en": "Sports",
        "description_en": "Sports/Physical fitness/Extreme sports/Sports events or competitions\r",
        "reason": [
            {
                "reason_en": "Sweating and exercising are just comfortable; love to physically work the body",
                "reason_cn": "流汗、運動很舒服，喜歡活動身體",
                "type": 5
            },
            {
                "reason_en": "Teamwork or a sense of belonging",
                "reason_cn": "團隊合作，或有團體歸屬感",
                "type": 4
            },
            {
                "reason_en": "In pursuit of individual performance or score",
                "reason_cn": "追求個人表現或成績",
                "type": 9
            },
            {
                "reason_en": "Challenge difficulty and the self",
                "reason_cn": "挑戰難度、挑戰自我",
                "type": 9
            },
            {
                "reason_en": "In pursuit of a sense of thrill",
                "reason_cn": "追求刺激感",
                "type": 5
            },
            {
                "reason_en": "Maintain body in shape to have physical attractiveness",
                "reason_cn": "維持身材以提高外表吸引力",
                "type": 5
            },
            {
                "reason_en": "Value health",
                "reason_cn": "重視健康",
                "type": 12
            },
            {
                "reason_en": "Love competition",
                "reason_cn": "喜歡比賽、競爭",
                "type": 9
            },
            {
                "reason_en": "Instruct others",
                "reason_cn": "指導他人",
                "type": 9
            },
            {
                "reason_en": "Love to be in the nature",
                "reason_cn": "喜歡在大自然中",
                "type": 12
            },
            {
                "reason_en": "Like to persevere, or repeat actions (e.g. long distance running, repeating a movement)",
                "reason_cn": "喜歡持之以恆、或重複動作(例如：長跑、重複訓練某個動作)",
                "type": 6
            },
            {
                "reason_en": "Stress relief and regulate emotions",
                "reason_cn": "紓壓、調節情緒",
                "type": 2
            },
            {
                "reason_en": "Enjoy studying the tactics, equipments and methods",
                "reason_cn": "喜歡研究戰術、器具、方式",
                "type": 1
            },
            {
                "reason_en": "A sense of achievement",
                "reason_cn": "成就感",
                "type": 9
            },
            {
                "reason_en": "Free, unrestrained and comfortable",
                "reason_cn": "自由、奔放、舒暢",
                "type": 5
            },
            {
                "reason_en": "Accompany others in exercise",
                "reason_cn": "陪他人運動",
                "type": 11
            },
            {
                "reason_en": "Health is wealth",
                "reason_cn": "健康就是財富",
                "type": 8
            },
            {
                "reason_en": "A stage for showing the self",
                "reason_cn": "有舞台可以展現自己",
                "type": 9
            },
            {
                "reason_en": "Communicate and interact with others",
                "reason_cn": "與他人交流互動",
                "type": [4, 10, 11]
            },
            {
                "reason_en": "Expanding connections",
                "reason_cn": "拓展人脈",
                "type": [8, 10]
            },
            {
                "reason_en": "Research on scientific training method",
                "reason_cn": "研究科學訓練方法",
                "type": 1
            },
            {
                "reason_en": "Get to know myself, interact with my inner-self",
                "reason_cn": "從運動中認識自己、和自己對話",
                "type": 2
            }
        ]
    },
    {
        "id": 23,
        "name_cn": "舒活",
        "description_cn": "散步/騎腳踏車/活動身體/遛寵物",
        "name_en": "Comfortable lifestyle \r",
        "description_en": "Strolling/Bike riding/Physical activities/Walk the pet",
        "reason": [
            {
                "reason_en": "Love sightseeing and enjoy being in the nature",
                "reason_cn": "看風景、喜歡到大自然中",
                "type": 12
            },
            {
                "reason_en": "Be helpful for thinking",
                "reason_cn": "幫助思考",
                "type": 1
            },
            {
                "reason_en": "Go for a ride and enjoy",
                "reason_cn": "吹風、享受",
                "type": 5
            },
            {
                "reason_en": "Being alone, free and feeling at peace",
                "reason_cn": "安靜的獨處、自在",
                "type": 2
            },
            {
                "reason_en": "Physical activities",
                "reason_cn": "活動身體",
                "type": 5
            },
            {
                "reason_en": "Mind rest",
                "reason_cn": "沉澱心情",
                "type": 2
            },
            {
                "reason_en": "Pick up and drop off",
                "reason_cn": "提供接送",
                "type": 11
            },
            {
                "reason_en": "Convenient and practical",
                "reason_cn": "方便、實用",
                "type": 6
            },
            {
                "reason_en": "Make comfortable lifestyle a habit\nEnjoy doing something persistently",
                "reason_cn": "養成舒活的習慣喜歡持之以恆做某事",
                "type": [6, 7]
            },
            {
                "reason_en": "Healthy",
                "reason_cn": "健康",
                "type": 12
            },
            {
                "reason_en": "Together with others",
                "reason_cn": "和他人一起",
                "type": [4, 10, 11]
            },
            {
                "reason_en": "Love to interact with animals and plants",
                "reason_cn": "喜歡和動植物相處",
                "type": 12
            },
            {
                "reason_en": "Accompany others",
                "reason_cn": "陪伴他人",
                "type": 11
            },
            {
                "reason_en": "Having activities with others",
                "reason_cn": "和他人一起活動",
                "type": 4
            },
            {
                "reason_en": "Having small talks, talking",
                "reason_cn": "聊天、講話",
                "type": 10
            },
            {
                "reason_en": "Get to know new friends",
                "reason_cn": "認識新朋友",
                "type": [4, 10]
            },
            {
                "reason_en": "Find some inspirations",
                "reason_cn": "尋找靈感",
                "type": [2, 3]
            },
            {
                "reason_en": "It`s a responsibility to take care of pets",
                "reason_cn": "照顧寵物是種責任",
                "type": [6, 12]
            }
        ]
    },
    {
        "id": 24,
        "name_cn": "美食",
        "description_cn": "吃東西/煮飯菜/烹飪",
        "name_en": "Gourmet",
        "description_en": "Eating/Cooking",
        "reason": [
            {
                "reason_en": "Enjoy gourmet",
                "reason_cn": "享受美食",
                "type": 5
            },
            {
                "reason_en": "Research about cooking materials and methods",
                "reason_cn": "研究食材與方法",
                "type": 1
            },
            {
                "reason_en": "Comment on gourmet and share the dining experiences",
                "reason_cn": "評論美食、寫食記",
                "type": 5
            },
            {
                "reason_en": "Love the dinning environment",
                "reason_cn": "喜歡餐飲環境",
                "type": 11
            },
            {
                "reason_en": "Love to be together with family and friends",
                "reason_cn": "喜歡和親友一起",
                "type": [4, 10, 11]
            },
            {
                "reason_en": "Love to cook for others",
                "reason_cn": "喜歡煮東西給別人吃",
                "type": 11
            },
            {
                "reason_en": "Enjoy cooking",
                "reason_cn": "喜歡動手做料理",
                "type": 6
            },
            {
                "reason_en": "Enjoy caring for others",
                "reason_cn": "喜歡照顧他人",
                "type": 4
            },
            {
                "reason_en": "Enjoy gourmet and life",
                "reason_cn": "享受美食與生活",
                "type": 5
            },
            {
                "reason_en": "Cook by self are healthier and safer",
                "reason_cn": "自己煮比較健康、衛生",
                "type": 12
            },
            {
                "reason_en": "Cook by self is more economical",
                "reason_cn": "經濟實惠，自己煮比較省錢",
                "type": [6, 8]
            },
            {
                "reason_en": "Collecting, organizing or recording recipes",
                "reason_cn": "蒐集、整理或記錄食譜",
                "type": 7
            },
            {
                "reason_en": "Innovative cooking method",
                "reason_cn": "創新做法",
                "type": 3
            },
            {
                "reason_en": "Love simple and routine life",
                "reason_cn": "喜歡規律單純的生活",
                "type": [6, 7]
            },
            {
                "reason_en": "Opportunity for small business",
                "reason_cn": "有商機，可以做小生意",
                "type": 8
            },
            {
                "reason_en": "Actively recommending good foods to others",
                "reason_cn": "主動推薦好吃的給其他人",
                "type": 10
            },
            {
                "reason_en": "Care about dietary balance",
                "reason_cn": "注重營養均衡",
                "type": 12
            },
            {
                "reason_en": "Calculate calories to keep self in shape",
                "reason_cn": "計算熱量，維持身材",
                "type": 5
            },
            {
                "reason_en": "Use natural food materials and less additives",
                "reason_cn": "使用天然食材，少添加物",
                "type": 12
            },
            {
                "reason_en": "Care about the display or the decoration of course",
                "reason_cn": "講究食物擺盤或裝飾美觀",
                "type": 3
            },
            {
                "reason_en": "Take gourmet as a way of taking care of self and nurturing body and mind",
                "reason_cn": "用美食來自我照顧、滋養身心",
                "type": 2
            },
            {
                "reason_en": "Tastes are full of memories",
                "reason_cn": "充滿回憶的味道",
                "type": 4
            },
            {
                "reason_en": "The deliciousness of the food will influence my mood",
                "reason_cn": "美味與否會影響心情",
                "type": 5
            }
        ]
    },
    {
        "id": 25,
        "name_cn": "手作",
        "description_cn": "手工製作物品/手工藝/勞作",
        "name_en": "Handicrafts ",
        "description_en": "Handmade items/Handicrafts/ Handiworks\r",
        "reason": [
            {
                "reason_en": "Enjoy do things by self",
                "reason_cn": "喜歡動手做",
                "type": 6
            },
            {
                "reason_en": "Love cutting, splicing and assembling",
                "reason_cn": "喜歡切割、拼接、組裝",
                "type": 6
            },
            {
                "reason_en": "Love handicrafts and creation of small things",
                "reason_cn": "喜歡創作手工藝、小物",
                "type": 3
            },
            {
                "reason_en": "Communicate and share with others",
                "reason_cn": "與他人交流分享",
                "type": 4
            },
            {
                "reason_en": "Love to try different color and style combinations",
                "reason_cn": "喜歡嘗試顏色、樣式搭配",
                "type": 3
            },
            {
                "reason_en": "Business opportunity and price appreciation",
                "reason_cn": "有商機、增值空間",
                "type": 8
            },
            {
                "reason_en": "Give works to others as gifts",
                "reason_cn": "喜歡贈送作品給他人",
                "type": 11
            },
            {
                "reason_en": "Help family and friends to make things they need",
                "reason_cn": "幫親友製作需要的物品",
                "type": 11
            },
            {
                "reason_en": "Practicality with self-made products",
                "reason_cn": "自己做很實用",
                "type": 6
            },
            {
                "reason_en": "Peaceful mind and satisfactory",
                "reason_cn": "心靈平靜、很療癒",
                "type": 2
            },
            {
                "reason_en": "Love to do things quietly and alone",
                "reason_cn": "喜歡獨自靜靜地做事",
                "type": 6
            },
            {
                "reason_en": "Love natural or animal and plant materials",
                "reason_cn": "喜歡自然或動植物素材",
                "type": 12
            },
            {
                "reason_en": "Love to study all different methods",
                "reason_cn": "喜歡研究各種作法",
                "type": 1
            },
            {
                "reason_en": "Environmentally friendly using recycling materials",
                "reason_cn": "隨手回收再利用材料，很環保",
                "type": 12
            },
            {
                "reason_en": "Make hands-on efforts is more economical",
                "reason_cn": "自己動手做比較經濟實惠",
                "type": [6, 8]
            },
            {
                "reason_en": "Love to employ alternative methods",
                "reason_cn": "喜歡跳脫原本應有的做法",
                "type": 3
            },
            {
                "reason_en": "Able to create all kinds of possibilities",
                "reason_cn": "舉一反三，創造各種可能性",
                "type": 3
            },
            {
                "reason_en": "Some part of the creation is like myself",
                "reason_cn": "作品的某部分蠻像自己的",
                "type": 2
            },
            {
                "reason_en": "Get to know myself better through the process of creating",
                "reason_cn": "創作過程更認識自己",
                "type": 2
            }
        ]
    },
    {
        "id": 26,
        "name_cn": "裝修",
        "description_cn": "裝修物品、電器",
        "name_en": "Decoration ",
        "description_en": "Decorative items, electrical appliances",
        "reason": [
            {
                "reason_en": "Enjoy doing and assembling things by self",
                "reason_cn": "喜歡動手操作、組裝",
                "type": 6
            },
            {
                "reason_en": "Enjoy fixing and eliminating problems by self",
                "reason_cn": "喜歡動手修理、排除問題",
                "type": 6
            },
            {
                "reason_en": "Enjoy studying or learning",
                "reason_cn": "喜歡研究或學習",
                "type": 1
            },
            {
                "reason_en": "Enjoy thinking and solving problem",
                "reason_cn": "喜歡思考解決問題",
                "type": 1
            },
            {
                "reason_en": "Be a little helper to others",
                "reason_cn": "當小幫手協助他人",
                "type": 11
            },
            {
                "reason_en": "Practical when doing things by self",
                "reason_cn": "自己動手很實用",
                "type": 6
            },
            {
                "reason_en": "Business opportunity",
                "reason_cn": "有商機",
                "type": 8
            },
            {
                "reason_en": "Recycle and appreciate earth resources",
                "reason_cn": "回收、愛惜地球資源",
                "type": 12
            },
            {
                "reason_en": "Not following the rules and create new results",
                "reason_cn": "不按部就班，創造新的結果",
                "type": 3
            },
            {
                "reason_en": "Make life more convenient to meet own needs",
                "reason_cn": "讓生活更便利、符合自己的需求",
                "type": 6
            },
            {
                "reason_en": "Meet the needs of users",
                "reason_cn": "符合使用者的需求",
                "type": 11
            },
            {
                "reason_en": "Money saving and not wasting",
                "reason_cn": "省錢、不浪費",
                "type": 6
            },
            {
                "reason_en": "Completely follow the procedure to avoid mistakes",
                "reason_cn": "完全照步驟做以免出錯",
                "type": 7
            },
            {
                "reason_en": "In pursuit of perfection",
                "reason_cn": "追求完美",
                "type": 9
            },
            {
                "reason_en": "Enjoy the sensation after modification",
                "reason_cn": "改裝後提升感官享受",
                "type": 5
            },
            {
                "reason_en": "Enjoy a sense of achievement with hands-on operation",
                "reason_cn": "動手做很有成就感",
                "type": 6
            },
            {
                "reason_en": "Love to do things quietly and alone",
                "reason_cn": "喜歡一個人靜靜地做事",
                "type": 6
            },
            {
                "reason_en": "Able to communicate and exchange with others",
                "reason_cn": "可以與他人交流",
                "type": 4
            },
            {
                "reason_en": "Recommend, share good tools or methods to others",
                "reason_cn": "推薦、分享好工具或作法",
                "type": 10
            },
            {
                "reason_en": "Love taking time restoring details",
                "reason_cn": "喜歡慢慢處理或修復細節",
                "type": 7
            }
        ]
    },
    {
        "id": 27,
        "name_cn": "拼湊",
        "description_cn": "模型製作/拼圖\r",
        "name_en": "Assembly",
        "description_en": "Model making/Puzzles",
        "reason": [
            {
                "reason_en": "Enjoy do things by self",
                "reason_cn": "喜歡動手做",
                "type": 6
            },
            {
                "reason_en": "Love assembling and splicing",
                "reason_cn": "喜歡組裝、拼接",
                "type": 6
            },
            {
                "reason_en": "Love observing, thinking and researching",
                "reason_cn": "喜歡觀察、思考、研究",
                "type": 1
            },
            {
                "reason_en": "Prefer following the rules, step by step to completion",
                "reason_cn": "喜歡按規則、逐步完成",
                "type": [6, 7]
            },
            {
                "reason_en": "Communicate and share with others",
                "reason_cn": "與他人交流分享",
                "type": 4
            },
            {
                "reason_en": "Business opportunity and price appreciation",
                "reason_cn": "有商機、增值空間",
                "type": 8
            },
            {
                "reason_en": "Love collecting various puzzles, models, dolls, etc.",
                "reason_cn": "喜歡蒐集各種拼圖、模型、公仔…等",
                "type": 7
            },
            {
                "reason_en": "Give works to others as gifts",
                "reason_cn": "喜歡贈送作品給他人",
                "type": 11
            },
            {
                "reason_en": "Enjoy creating new method or style from existing parts",
                "reason_cn": "喜歡從既有的零件發揮巧思產生新做法或新樣式",
                "type": 3
            },
            {
                "reason_en": "Practicality with self-made products",
                "reason_cn": "自己做很實用",
                "type": 6
            },
            {
                "reason_en": "Peaceful mind and satisfactory",
                "reason_cn": "心靈平靜、很療癒",
                "type": 2
            },
            {
                "reason_en": "Love to do things quietly and alone",
                "reason_cn": "喜歡獨自靜靜地做事",
                "type": 6
            },
            {
                "reason_en": "Love natural or animal and plant materials",
                "reason_cn": "喜歡自然或動植物素材",
                "type": 12
            },
            {
                "reason_en": "Enjoy the sense of achievement after conquest of challenge",
                "reason_cn": "享受征服困難後的成就感",
                "type": 9
            },
            {
                "reason_en": "Enjoy high similarity with the original drawing (object), and have a sense of accomplishment",
                "reason_cn": "喜歡和原圖(物)相似度高，很有成就感",
                "type": 7
            },
            {
                "reason_en": "Love to learn all different methods",
                "reason_cn": "喜歡學習各種做法",
                "type": 1
            },
            {
                "reason_en": "Love details",
                "reason_cn": "喜歡處理細節",
                "type": 7
            },
            {
                "reason_en": "Love to sorts things and then take time dealing with the rests",
                "reason_cn": "喜歡先分類後再慢慢處理",
                "type": 7
            }
        ]
    },
    {
        "id": 28,
        "name_cn": "家務",
        "description_cn": "做家事/整理/分類",
        "name_en": "House chores",
        "description_en": "House chores/Organization/Classification",
        "reason": [
            {
                "reason_en": "Actively do house chores and cleaning",
                "reason_cn": "動手做家事、清潔",
                "type": 6
            },
            {
                "reason_en": "Organization, classification and storage",
                "reason_cn": "整理、分類、收納",
                "type": 7
            },
            {
                "reason_en": "Love to organize documents",
                "reason_cn": "喜歡整理文件",
                "type": 7
            },
            {
                "reason_en": "Prefer neatness and regularity",
                "reason_cn": "喜歡井然有序、有規則",
                "type": 7
            },
            {
                "reason_en": "Provide a comfortable environment for family",
                "reason_cn": "提供家人舒適的環境",
                "type": 11
            },
            {
                "reason_en": "Inclination to cleanliness",
                "reason_cn": "有潔癖傾向",
                "type": 7
            },
            {
                "reason_en": "A sense of achievement from cleaning and organizing",
                "reason_cn": "打掃整理完很有成就感",
                "type": 6
            },
            {
                "reason_en": "Saving time and money",
                "reason_cn": "節省時間和金錢",
                "type": 6
            },
            {
                "reason_en": "Improving quality of life",
                "reason_cn": "提高生活品質",
                "type": 5
            },
            {
                "reason_en": "Responsibilities and individual duty",
                "reason_cn": "責任、份內的事",
                "type": [6, 7]
            },
            {
                "reason_en": "Promoting health, exercising physically",
                "reason_cn": "促進健康、活動身體",
                "type": 12
            },
            {
                "reason_en": "Study efficient methods for house chores",
                "reason_cn": "研究有效率的家事方法",
                "type": 1
            },
            {
                "reason_en": "Share family`s burden and worries",
                "reason_cn": "幫家人分憂解勞",
                "type": 11
            },
            {
                "reason_en": "Thinking while doing house chores",
                "reason_cn": "邊做家事邊想事情",
                "type": 2
            },
            {
                "reason_en": "A way to express the care for families",
                "reason_cn": "關愛家人的表現",
                "type": 4
            },
            {
                "reason_en": "Arrange in accordance to the needs of families",
                "reason_cn": "依家人需要來安排",
                "type": 4
            },
            {
                "reason_en": "Put one`s genuine ideas into house chores",
                "reason_cn": "把小巧思運用在家務上",
                "type": 3
            },
            {
                "reason_en": "Practice environment preservation",
                "reason_cn": "落實環保",
                "type": 12
            },
            {
                "reason_en": "Unable to think well when the surrounding area is chaotic",
                "reason_cn": "周圍很亂會無法思考",
                "type": 7
            }
        ]
    },
    {
        "id": 29,
        "name_cn": "記錄\r",
        "description_cn": "寫日記/手帳/網誌",
        "name_en": "Record",
        "description_en": "Diary/Handbook or schedule booklet/Blog",
        "reason": [
            {
                "reason_en": "Record thoughts and feelings",
                "reason_cn": "記錄想法或心情",
                "type": 2
            },
            {
                "reason_en": "Express emotions and feelings",
                "reason_cn": "抒發情緒、表達感受",
                "type": 2
            },
            {
                "reason_en": "Practice the aesthetics and skills of writing",
                "reason_cn": "練文筆、寫作能力",
                "type": 3
            },
            {
                "reason_en": "Love to organize information",
                "reason_cn": "喜歡整理資訊",
                "type": 7
            },
            {
                "reason_en": "Love to quietly do things alone",
                "reason_cn": "喜歡靜靜的獨自做事",
                "type": 7
            },
            {
                "reason_en": "Love to notice every detail in life",
                "reason_cn": "喜歡留意生活細節",
                "type": 7
            },
            {
                "reason_en": "Love to use various stationery (ex. Pens, labels, tapes , etc.)",
                "reason_cn": "喜歡運用各種文具(例如：不同的筆、標籤、紙膠帶等)",
                "type": 7
            },
            {
                "reason_en": "Deliberately record details of daily life (ex. Expenses, itinerary and activities)",
                "reason_cn": "清楚記錄生活層面(例如：花費、行程、活動)",
                "type": 7
            },
            {
                "reason_en": "Share life and feelings",
                "reason_cn": "分享生活與心情",
                "type": 4
            },
            {
                "reason_en": "Increase the click through rate",
                "reason_cn": "增加點閱率",
                "type": 9
            },
            {
                "reason_en": "Managing blog and personal webpage",
                "reason_cn": "經營網誌、個人網頁",
                "type": 9
            },
            {
                "reason_en": "Business opportunity",
                "reason_cn": "創造商機",
                "type": 8
            },
            {
                "reason_en": "Use revenue and expenditure records for financial planning",
                "reason_cn": "把收支記錄用以規劃財務",
                "type": [7, 8]
            },
            {
                "reason_en": "Share useful information with others",
                "reason_cn": "將有用的資訊分享給他人",
                "type": 11
            },
            {
                "reason_en": "Actively recommending useful stationeries to others",
                "reason_cn": "主動推薦好用的文具給其他人",
                "type": 10
            },
            {
                "reason_en": "Write professional knowledge and skills for sharing with others",
                "reason_cn": "寫專業知識、技巧的分享文章",
                "type": 1
            },
            {
                "reason_en": "Simply just enjoy writing",
                "reason_cn": "就是喜歡動手隨意抄抄寫寫東西",
                "type": [6, 7]
            }
        ]
    },
    {
        "id": 30,
        "name_cn": "蒐集\r",
        "description_cn": "蒐集東西/集郵/集冊\r",
        "name_en": "Collection",
        "description_en": "Collecting things, stamps or booklets",
        "reason": [
            {
                "reason_en": "Enjoy the sense of complete collection",
                "reason_cn": "喜歡蒐集完整的感覺",
                "type": 7
            },
            {
                "reason_en": "Love organizing and counting things",
                "reason_cn": "喜歡整理、細數物品",
                "type": 7
            },
            {
                "reason_en": "Love categorizing things",
                "reason_cn": "喜歡分類",
                "type": 7
            },
            {
                "reason_en": "Valuable collection (in sets) to sales",
                "reason_cn": "蒐集(成套)可以增值賣錢",
                "type": 8
            },
            {
                "reason_en": "Things are attractive and likable",
                "reason_cn": "東西很讓人喜歡",
                "type": 5
            },
            {
                "reason_en": "Admiring and playful",
                "reason_cn": "欣賞、玩味",
                "type": 3
            },
            {
                "reason_en": "Collecting various beautiful materials",
                "reason_cn": "蒐集各種美的素材",
                "type": 3
            },
            {
                "reason_en": "Insist to get the desirable things",
                "reason_cn": "對喜歡的東西會很執著地想要",
                "type": 7
            },
            {
                "reason_en": "Enjoy discussion and exchange with others",
                "reason_cn": "可以與他人交流討論",
                "type": 4
            },
            {
                "reason_en": "Provide objects or resources that others lack",
                "reason_cn": "提供他人缺少的物件或資源",
                "type": 11
            },
            {
                "reason_en": "Instruct others",
                "reason_cn": "指導他人",
                "type": 9
            },
            {
                "reason_en": "Study different methods of collecting and preserving",
                "reason_cn": "研究收藏保存的方式",
                "type": 1
            },
            {
                "reason_en": "Collect animal or plant related objects",
                "reason_cn": "收集自然或動植物相關的主題",
                "type": 12
            },
            {
                "reason_en": "Love to take time processing things or restoring the details",
                "reason_cn": "喜歡慢慢處理或修復細節",
                "type": 7
            },
            {
                "reason_en": "Prompting others to purchase or collect things",
                "reason_cn": "聳恿他人購買、蒐集",
                "type": 10
            }
        ]
    },
    {
        "id": 31,
        "name_cn": "美編",
        "description_cn": "美編/修圖",
        "name_en": "Art editing",
        "description_en": "Art editing/Retouching\r",
        "reason": [
            {
                "reason_en": "Patiently carry out detailed steps such as retouching and fine adjustment",
                "reason_cn": "耐心進行修圖、微調等細節的步驟",
                "type": 7
            },
            {
                "reason_en": "A sense of achievement in computer paperworks",
                "reason_cn": "電腦文書作業很有成就感",
                "type": 7
            },
            {
                "reason_en": "Love producing films",
                "reason_cn": "喜歡製作影片",
                "type": 3
            },
            {
                "reason_en": "Love storytelling through graphical display",
                "reason_cn": "喜歡用畫面說故事",
                "type": 3
            },
            {
                "reason_en": "Expecting appraisal and affirmation",
                "reason_cn": "期待被稱讚、肯定",
                "type": 9
            },
            {
                "reason_en": "Able to bring happiness to others",
                "reason_cn": "可以帶給他人娛樂效果",
                "type": 11
            },
            {
                "reason_en": "Love learning and studying hardware and software",
                "reason_cn": "喜歡學習和研究軟硬體",
                "type": 1
            },
            {
                "reason_en": "Seeking fame and great click through rate",
                "reason_cn": "希望有知名度、點閱率",
                "type": 9
            },
            {
                "reason_en": "Practical, make pictures and videos by self in respond to self-needs",
                "reason_cn": "很實用、可以自己做符合需要的圖片或影片",
                "type": 6
            },
            {
                "reason_en": "In pursuit of perfection",
                "reason_cn": "追求完美",
                "type": 9
            },
            {
                "reason_en": "Sharing works",
                "reason_cn": "分享作品",
                "type": 4
            },
            {
                "reason_en": "Love organizing and categorizing things",
                "reason_cn": "喜歡整理與分類",
                "type": 7
            },
            {
                "reason_en": "Love photographing figures or recording family life",
                "reason_cn": "喜歡拍人物，或記錄家庭生活",
                "type": 4
            },
            {
                "reason_en": "Love taking pictures of animals/plants and the nature",
                "reason_cn": "喜歡拍動植物、大自然",
                "type": 12
            },
            {
                "reason_en": "Clear presentation with tables and pictures",
                "reason_cn": "用圖表整理很清楚",
                "type": 7
            },
            {
                "reason_en": "Improve marketing and sales pitch",
                "reason_cn": "增加廣告行銷的效果",
                "type": 8
            },
            {
                "reason_en": "Simply wishing good presentation",
                "reason_cn": "希望外表好看",
                "type": 5
            },
            {
                "reason_en": "Love to present abstract concept or procedure in pictures and tables",
                "reason_cn": "喜歡把抽象的概念或流程，作成圖表來呈現",
                "type": [1, 7]
            }
        ]
    },
    {
        "id": 32,
        "name_cn": "影片",
        "description_cn": "製作影片",
        "name_en": "Videos",
        "description_en": "Film production",
        "reason": [
            {
                "reason_en": "Patiently carry out detailed steps such as retouching and fine adjustment",
                "reason_cn": "耐心進行修圖、微調等細節的步驟",
                "type": 7
            },
            {
                "reason_en": "A sense of achievement in computer paperworks",
                "reason_cn": "電腦文書作業很有成就感",
                "type": 7
            },
            {
                "reason_en": "Love producing films",
                "reason_cn": "喜歡製作影片",
                "type": 3
            },
            {
                "reason_en": "Love storytelling through graphical display",
                "reason_cn": "喜歡用畫面說故事",
                "type": 3
            },
            {
                "reason_en": "Expecting appraisal and affirmation",
                "reason_cn": "期待被稱讚、肯定",
                "type": 9
            },
            {
                "reason_en": "Able to entertain others",
                "reason_cn": "可以帶給他人娛樂效果",
                "type": 11
            },
            {
                "reason_en": "Love learning and studying hardware and software",
                "reason_cn": "喜歡學習和研究軟硬體",
                "type": 1
            },
            {
                "reason_en": "Seeking fame and great click through rate",
                "reason_cn": "希望有知名度、點閱率",
                "type": 9
            },
            {
                "reason_en": "In pursuit of perfection",
                "reason_cn": "追求完美",
                "type": 9
            },
            {
                "reason_en": "Sharing works",
                "reason_cn": "分享作品",
                "type": 4
            },
            {
                "reason_en": "Love organizing and categorizing things",
                "reason_cn": "喜歡整理與分類",
                "type": 7
            },
            {
                "reason_en": "Love taking pictures of animals/plants and the nature",
                "reason_cn": "喜歡拍動植物、大自然",
                "type": 12
            },
            {
                "reason_en": "Love photographing people",
                "reason_cn": "喜歡拍人物",
                "type": 4
            },
            {
                "reason_en": "Practical, make videos by self in respond to self-needs",
                "reason_cn": "很實用、可以自己做符合需要的影片",
                "type": 6
            },
            {
                "reason_en": "Marketing and sales",
                "reason_cn": "廣告行銷",
                "type": 8
            },
            {
                "reason_en": "Image building",
                "reason_cn": "營造形象",
                "type": 8
            },
            {
                "reason_en": "Convey concepts for persuasion",
                "reason_cn": "傳達概念來說服他人",
                "type": 10
            },
            {
                "reason_en": "Love directing and want to be a director",
                "reason_cn": "喜歡主導、當導演",
                "type": 9
            },
            {
                "reason_en": "Life account as a way of recollection and memory",
                "reason_cn": "記錄生活，作為一種回憶與紀念",
                "type": [2, 4]
            }
        ]
    },
    {
        "id": 33,
        "name_cn": "金錢",
        "description_cn": "賺錢/存錢/比價",
        "name_en": "Money",
        "description_en": "Making money / saving money/ Price comparison",
        "reason": [
            {
                "reason_en": "Love to cumulate fortunes",
                "reason_cn": "喜歡累積財富",
                "type": 8
            },
            {
                "reason_en": "Pay attention to the methods of saving money",
                "reason_cn": "留意省錢的方法",
                "type": 8
            },
            {
                "reason_en": "Making well financial plans",
                "reason_cn": "做好財務規劃",
                "type": 8
            },
            {
                "reason_en": "Positively obtain the chances to earn money",
                "reason_cn": "積極爭取賺錢的機會",
                "type": [8, 10]
            },
            {
                "reason_en": "Record details of earning and paying statements",
                "reason_cn": "詳細記錄收支明細",
                "type": 7
            },
            {
                "reason_en": "A status of identity, a sense of superiority",
                "reason_cn": "身分地位、有優越感",
                "type": 9
            },
            {
                "reason_en": "Earning money is practical, a sense of security",
                "reason_cn": "賺錢很實在、有安全感",
                "type": 6
            },
            {
                "reason_en": "Able to purchase things arbitrarily, satisfy what one desires",
                "reason_cn": "有錢可以任性地買、滿足想要的",
                "type": 5
            },
            {
                "reason_en": "Freedom of fortune, be able to play and enjoy life",
                "reason_cn": "財富自由，可以玩、享受生活",
                "type": 5
            },
            {
                "reason_en": "Enjoy doing things step by step",
                "reason_cn": "喜歡按部就班做事",
                "type": 7
            },
            {
                "reason_en": "Want to share family`s finance, or want to provide family with a better life",
                "reason_cn": "想幫忙分擔家計，想提供家人更好的生活",
                "type": [4, 11]
            },
            {
                "reason_en": "Donate, help others",
                "reason_cn": "捐獻、幫助他人",
                "type": [4, 11]
            },
            {
                "reason_en": "Realize dreams, pursue goals",
                "reason_cn": "實現夢想、追求目標",
                "type": 9
            },
            {
                "reason_en": "Love to do researches in investment and financial management",
                "reason_cn": "喜歡研究投資理財",
                "type": [1, 8, 10]
            },
            {
                "reason_en": "Love commercial activities",
                "reason_cn": "喜歡商業活動",
                "type": 8
            },
            {
                "reason_en": "Achieve the goal of life plans",
                "reason_cn": "達成人生規劃",
                "type": 7
            },
            {
                "reason_en": "The necessaries for life, to make a living for family",
                "reason_cn": "生活必須、養家活口",
                "type": 6
            },
            {
                "reason_en": "Value the very increases of gains following the efforts one has made",
                "reason_cn": "重視努力後立刻有實質報酬",
                "type": 6
            },
            {
                "reason_en": "Considerate and plan deliberately",
                "reason_cn": "精打細算",
                "type": [7, 8]
            },
            {
                "reason_en": "Money is able to help solve problems",
                "reason_cn": "有錢可以幫忙解決問題",
                "type": 11
            },
            {
                "reason_en": "Money is power",
                "reason_cn": "金錢就是權力",
                "type": [8, 9]
            },
            {
                "reason_en": "Money can produce money",
                "reason_cn": "錢會生錢，錢滾錢",
                "type": 8
            }
        ]
    },
    {
        "id": 34,
        "name_cn": "財經",
        "description_cn": "投資理財/關注商業訊息",
        "name_en": "Finance",
        "description_en": "Financial investment and management / pay attention to commercial information\r",
        "reason": [
            {
                "reason_en": "Love accumulating wealth",
                "reason_cn": "喜歡累積、創造財富",
                "type": 8
            },
            {
                "reason_en": "Do a good job in financial planning",
                "reason_cn": "做好財務規劃",
                "type": 8
            },
            {
                "reason_en": "Pursue social status and a sense of superiority",
                "reason_cn": "追求身分地位、有優越感",
                "type": 9
            },
            {
                "reason_en": "Solid feeling from making money and a sense of security",
                "reason_cn": "賺錢存錢很實在、有安全感",
                "type": 6
            },
            {
                "reason_en": "Able to purchase or satisfy the needs, enjoy lives",
                "reason_cn": "可以購買或滿足想要的、享受生活",
                "type": 5
            },
            {
                "reason_en": "Earn money the easy way",
                "reason_cn": "可以用輕鬆的方式賺錢",
                "type": 5
            },
            {
                "reason_en": "Recommend others the financial management methods or products",
                "reason_cn": "推薦、說服他人理財方法或商品",
                "type": 10
            },
            {
                "reason_en": "Wish to help sharing the family burden or do public charity",
                "reason_cn": "想要幫忙分擔家計或做公益",
                "type": [4, 11]
            },
            {
                "reason_en": "Desire to provide a better life for others",
                "reason_cn": "想提供他人更好的生活",
                "type": 11
            },
            {
                "reason_en": "Realizing dream and pursue a goal",
                "reason_cn": "實現夢想、追求目標",
                "type": 9
            },
            {
                "reason_en": "Enjoy things related to money",
                "reason_cn": "喜歡跟錢有關的事物",
                "type": 8
            },
            {
                "reason_en": "Achieving career plan",
                "reason_cn": "達成人生規劃",
                "type": 7
            },
            {
                "reason_en": "Earn money efficiently",
                "reason_cn": "可以用有效率的方式賺錢",
                "type": 8
            },
            {
                "reason_en": "Understand the economic pulse of the world",
                "reason_cn": "了解世界的經濟脈動",
                "type": [1, 8]
            },
            {
                "reason_en": "Study and compare various investment plans",
                "reason_cn": "研究與比較各種投資方案",
                "type": [1, 8]
            },
            {
                "reason_en": "Research the trend of the financial market, or analyze investment-related data",
                "reason_cn": "研究金融市場走向，或分析投資相關數據",
                "type": 1
            },
            {
                "reason_en": "Plan carefully",
                "reason_cn": "精打細算",
                "type": [7, 8]
            },
            {
                "reason_en": "Find it exciting to watch commentator`s analyses",
                "reason_cn": "看名嘴犀利分析很過癮",
                "type": 10
            },
            {
                "reason_en": "Obtain passive or extra incomes",
                "reason_cn": "創造被動、額外收入",
                "type": 8
            },
            {
                "reason_en": "Able to help others manage their finance",
                "reason_cn": "可以幫助別人理財",
                "type": 11
            },
            {
                "reason_en": "Get freedom of fortune and autonomy of life",
                "reason_cn": "財富自由，自主人生",
                "type": [8, 9]
            },
            {
                "reason_en": "To obtain practical information to assist financial management",
                "reason_cn": "想吸收實際的資訊來協助理財",
                "type": 6
            },
            {
                "reason_en": "Analyze the reasons and emotions for one`s financial management actions",
                "reason_cn": "分析自己理財行動的原因與情緒",
                "type": [1, 2]
            },
            {
                "reason_en": "Not be afraid of failure in short-term, make plans deliberately to profit in long-term",
                "reason_cn": "不怕短期失利，籌謀佈局長期獲利",
                "type": 8
            },
            {
                "reason_en": "Analyze the perspectives of different media",
                "reason_cn": "分析各家媒體說法",
                "type": 1
            },
            {
                "reason_en": "Keep taking notes relating to finance",
                "reason_cn": "勤做相關筆記",
                "type": 7
            },
            {
                "reason_en": "Evaluate how to set the termination of losses and profits",
                "reason_cn": "評估停損與獲利了結的設定",
                "type": 8
            }
        ]
    },
    {
        "id": 35,
        "name_cn": "政治",
        "description_cn": "學生會、社團幹部、學會、協會、工會、公會、參加公共事務、\r\n關注政治或社會議題、參與政治活動或社會運動\r",
        "name_en": "Politics",
        "description_en": "Participating in public affairs/\r\n(For example: Student union, club cadres, associations, society)\r\nPay attention and participate in political activities or social issues\r",
        "reason": [
            {
                "reason_en": "More ability means more responsibility",
                "reason_cn": "能力愈高，責任愈大",
                "type": 9
            },
            {
                "reason_en": "Like to take the lead",
                "reason_cn": "喜歡領頭做事",
                "type": 9
            },
            {
                "reason_en": "Desire reform",
                "reason_cn": "想要有所改革",
                "type": [4, 9]
            },
            {
                "reason_en": "Work for the benefit of the public and help others",
                "reason_cn": "為大眾謀福利，幫助他人",
                "type": 4
            },
            {
                "reason_en": "Work for the public and serve others",
                "reason_cn": "為大眾做事，服務他人",
                "type": 11
            },
            {
                "reason_en": "A sense of achievement",
                "reason_cn": "有成就感",
                "type": 9
            },
            {
                "reason_en": "Focus and study specific issues",
                "reason_cn": "關注、研究特定議題",
                "type": 1
            },
            {
                "reason_en": "Feedback to the society",
                "reason_cn": "回饋社會",
                "type": 4
            },
            {
                "reason_en": "Love deliberation or persuasion of others",
                "reason_cn": "喜歡倡議或說服他人",
                "type": 10
            },
            {
                "reason_en": "Enjoy participating in debates",
                "reason_cn": "喜歡辯論的活動",
                "type": 10
            },
            {
                "reason_en": "Collect and organize politics-related information",
                "reason_cn": "蒐集整理相關議題的資料",
                "type": 7
            },
            {
                "reason_en": "Symbol of identity and social status",
                "reason_cn": "象徵身分地位",
                "type": 9
            },
            {
                "reason_en": "A sense of belonging",
                "reason_cn": "有歸屬感",
                "type": 4
            },
            {
                "reason_en": "Able to interact with others",
                "reason_cn": "可以與他人互動",
                "type": [4, 10]
            },
            {
                "reason_en": "Business opportunity when collaborating with organizations",
                "reason_cn": "與組織團體合作有商機",
                "type": 8
            },
            {
                "reason_en": "Defend one’s rights",
                "reason_cn": "捍衛自己的權益",
                "type": 9
            },
            {
                "reason_en": "Expanding connections",
                "reason_cn": "拓展人脈",
                "type": [8, 10]
            },
            {
                "reason_en": "Commit to nature issues like environmental protection and ecological environment",
                "reason_cn": "投入環保、生態等自然議題",
                "type": 12
            },
            {
                "reason_en": "Support the groups or people which can benefit oneself",
                "reason_cn": "支持對自己有利的團體或人物",
                "type": 8
            },
            {
                "reason_en": "Find it exciting to watch commentator`s analyses",
                "reason_cn": "看名嘴犀利激辯很過癮",
                "type": 10
            },
            {
                "reason_en": "Analyze the perspectives of different media",
                "reason_cn": "分析各家媒體說法",
                "type": 1
            },
            {
                "reason_en": "Apply activities and legislations to fight for rights",
                "reason_cn": "運用活動與法條來爭取權益",
                "type": [1, 9]
            },
            {
                "reason_en": "Fairness and justice are essential parts of a society",
                "reason_cn": "社會應該要有公平正義",
                "type": 12
            }
        ]
    },
    {
        "id": 36,
        "name_cn": "進步",
        "description_cn": "聽講座/讀書會/\r\n參加課程\r",
        "name_en": "Improvement",
        "description_en": "Lecture / Reading club/\r\nTaking course\r",
        "reason": [
            {
                "reason_en": "Knowledge is power",
                "reason_cn": "知識就是力量",
                "type": 9
            },
            {
                "reason_en": "Enjoy learning or thinking",
                "reason_cn": "喜歡學習或思考",
                "type": 1
            },
            {
                "reason_en": "Broaden the knowledge",
                "reason_cn": "增廣見聞",
                "type": 1
            },
            {
                "reason_en": "Improve myself constantly",
                "reason_cn": "讓自己不斷變強",
                "type": 9
            },
            {
                "reason_en": "A sense of belonging",
                "reason_cn": "有歸屬感",
                "type": 4
            },
            {
                "reason_en": "Able to communicate and interact with others",
                "reason_cn": "可以與人交流互動",
                "type": [4, 10]
            },
            {
                "reason_en": "Acquire more knowledge to improve persuasion",
                "reason_cn": "吸收資訊以提升說服力",
                "type": 10
            },
            {
                "reason_en": "Enjoy listening to various stories and experiences",
                "reason_cn": "喜歡聽各種故事和經驗",
                "type": 2
            },
            {
                "reason_en": "Increase competitiveness or professionalism",
                "reason_cn": "提升競爭力或專業性",
                "type": 9
            },
            {
                "reason_en": "Work requirement",
                "reason_cn": "職場所需",
                "type": 8
            },
            {
                "reason_en": "Accompany others in participation",
                "reason_cn": "陪伴他人參加",
                "type": 11
            },
            {
                "reason_en": "Feel like a liberal youth",
                "reason_cn": "感覺很文青",
                "type": 3
            },
            {
                "reason_en": "Focus on a specific issue",
                "reason_cn": "關注特定議題",
                "type": 2
            },
            {
                "reason_en": "Enjoy participating quietly by self",
                "reason_cn": "喜歡自己靜靜的參與就好",
                "type": 7
            },
            {
                "reason_en": "Share with others after the meeting",
                "reason_cn": "會後與他人分享心得",
                "type": 4
            },
            {
                "reason_en": "Enjoy organizing information and lecture notes",
                "reason_cn": "喜歡將資料、講義等整理好",
                "type": 7
            },
            {
                "reason_en": "Used to following specific lecturers",
                "reason_cn": "習慣追隨特定的講師",
                "type": 7
            },
            {
                "reason_en": "Enjoy spiritual foods or feel the peace of mind",
                "reason_cn": "享受心靈糧食，或感覺平靜",
                "type": 2
            },
            {
                "reason_en": "Expanding connections",
                "reason_cn": "拓展人脈",
                "type": [8, 10]
            },
            {
                "reason_en": "Maintain an active brain to prevent degeneration",
                "reason_cn": "保持大腦活動，預防退化",
                "type": 12
            },
            {
                "reason_en": "Obtain commercial information, observe the fluctuations of financial market",
                "reason_cn": "吸收商業資訊、觀察市場脈動",
                "type": 8
            },
            {
                "reason_en": "Indulge in the atmosphere of actively pursue ideals",
                "reason_cn": "沉浸在積極追求理想的氣氛中",
                "type": [8, 9]
            }
        ]
    },
    {
        "id": 37,
        "name_cn": "強化",
        "description_cn": "各方面的自我提升",
        "name_en": "Enhancement",
        "description_en": "Self enhancement in every aspect",
        "reason": [
            {
                "reason_en": "Knowledge is power",
                "reason_cn": "知識就是力量",
                "type": 9
            },
            {
                "reason_en": "Improve inner character and depth",
                "reason_cn": "增加內在涵養、深度",
                "type": 2
            },
            {
                "reason_en": "Enjoy learning new knowledge",
                "reason_cn": "喜歡學習新知",
                "type": 1
            },
            {
                "reason_en": "Love studying and contemplating",
                "reason_cn": "喜歡研究與思考",
                "type": 1
            },
            {
                "reason_en": "Increase competitiveness and ability",
                "reason_cn": "增加競爭力、能力",
                "type": 9
            },
            {
                "reason_en": "Continuously improve and grow",
                "reason_cn": "保持不斷地進步、成長",
                "type": 9
            },
            {
                "reason_en": "Enjoy communicating and interacting with others",
                "reason_cn": "可以與他人交流互動",
                "type": [4, 10]
            },
            {
                "reason_en": "Challenge self and breakthrough the limit",
                "reason_cn": "挑戰自我、突破極限",
                "type": 9
            },
            {
                "reason_en": "Help others solve problems",
                "reason_cn": "可以協助他人解決問題",
                "type": [4, 11]
            },
            {
                "reason_en": "Persuade others",
                "reason_cn": "可以說服他人",
                "type": 10
            },
            {
                "reason_en": "Improve quality of life",
                "reason_cn": "提升生活品質",
                "type": 5
            },
            {
                "reason_en": "Exercise body strength",
                "reason_cn": "鍛鍊身體強度",
                "type": 5
            },
            {
                "reason_en": "Practice hands-on skills",
                "reason_cn": "練習手作技能",
                "type": 6
            },
            {
                "reason_en": "Increase business opportunity or benefit",
                "reason_cn": "提升商機或效益",
                "type": 8
            },
            {
                "reason_en": "Increase wealth",
                "reason_cn": "增加財富",
                "type": 8
            },
            {
                "reason_en": "Enjoy doing something persistently",
                "reason_cn": "喜歡持之以恆做某事",
                "type": 6
            },
            {
                "reason_en": "Expanding connections",
                "reason_cn": "拓展人脈",
                "type": [8, 10]
            },
            {
                "reason_en": "Improve health",
                "reason_cn": "提升健康",
                "type": 12
            },
            {
                "reason_en": "Obtain commercial information, observe the fluctuations of financial market",
                "reason_cn": "吸收商業資訊、觀察市場脈動",
                "type": 8
            }
        ]
    },
    {
        "id": 38,
        "name_cn": "人脈\r",
        "description_cn": "經營人脈／認識新朋友／辦活動",
        "name_en": "Contacts",
        "description_en": "Managing contacts／make new friends／ activities\r",
        "reason": [
            {
                "reason_en": "Expanding business",
                "reason_cn": "拓展業務",
                "type": 10
            },
            {
                "reason_en": "Broaden the knowledge",
                "reason_cn": "增廣見聞",
                "type": 1
            },
            {
                "reason_en": "Finding the right help when encountering difficulties",
                "reason_cn": "遇到困難時能找到對的人協助",
                "type": 10
            },
            {
                "reason_en": "Love making friends and knowing different people",
                "reason_cn": "喜歡交朋友，認識各式各樣的人",
                "type": [4, 10]
            },
            {
                "reason_en": "Enjoy knowing more people and contacts",
                "reason_cn": "喜歡自己人面很廣",
                "type": 9
            },
            {
                "reason_en": "Observe and create business opportunity",
                "reason_cn": "觀察、創造商機",
                "type": 8
            },
            {
                "reason_en": "Love the crowd and gathering",
                "reason_cn": "喜歡熱鬧、聚會",
                "type": 5
            },
            {
                "reason_en": "Linking contacts or resources",
                "reason_cn": "連結人脈或資源",
                "type": 10
            },
            {
                "reason_en": "Observe and think about social interaction",
                "reason_cn": "觀察與思考人際互動",
                "type": [2, 4]
            },
            {
                "reason_en": "Good at expressing and sharing about self",
                "reason_cn": "擅於表達、分享自己",
                "type": [4, 10]
            },
            {
                "reason_en": "Actively sharing and recommending good things",
                "reason_cn": "主動分享、推薦好東西",
                "type": 10
            },
            {
                "reason_en": "Enjoy debating specific issues with others",
                "reason_cn": "樂於跟他人辯論某議題",
                "type": 10
            },
            {
                "reason_en": "Help others solve problems and be a helper",
                "reason_cn": "幫他人解決問題，當小幫手",
                "type": 11
            },
            {
                "reason_en": "Managing business to strive toward goal",
                "reason_cn": "經營事業，邁向目標",
                "type": [8, 9]
            },
            {
                "reason_en": "Contacts are money connections",
                "reason_cn": "人脈就是錢脈",
                "type": [8, 10]
            },
            {
                "reason_en": "Obtain commercial information, observe the fluctuations of the financial market",
                "reason_cn": "吸收商業資訊、觀察市場脈動",
                "type": 8
            }
        ]
    },
    {
        "id": 39,
        "name_cn": "講話",
        "description_cn": "攀談、鬥嘴、亂聊、碎唸",
        "name_en": "Talk",
        "description_en": "Strike up a conversation, bicker, small talk, nag",
        "reason": [
            {
                "reason_en": "Make a small talk to kill time",
                "reason_cn": "閒聊打發時間",
                "type": 5
            },
            {
                "reason_en": "Feeling accomplished when one prevail in talking",
                "reason_cn": "講贏別人很有成就感",
                "type": 10
            },
            {
                "reason_en": "Love the process of debating",
                "reason_cn": "喜歡辯論的過程",
                "type": 10
            },
            {
                "reason_en": "Express one’s abilities",
                "reason_cn": "展現自己的能力",
                "type": 9
            },
            {
                "reason_en": "Exchange knowledge",
                "reason_cn": "知識交流",
                "type": 1
            },
            {
                "reason_en": "Facilitate affections, communicate feelings",
                "reason_cn": "增進感情、交流心情",
                "type": 4
            },
            {
                "reason_en": "Upgrading talking skills",
                "reason_cn": "練習聊天的功力",
                "type": 10
            },
            {
                "reason_en": "Accompany others",
                "reason_cn": "陪伴他人",
                "type": 11
            },
            {
                "reason_en": "Attract attentions from others",
                "reason_cn": "吸引注意力",
                "type": 9
            },
            {
                "reason_en": "Get to know new acquaintances",
                "reason_cn": "認識新朋友",
                "type": 10
            },
            {
                "reason_en": "Love lively atmosphere, interesting, everyone`s happy",
                "reason_cn": "喜歡熱鬧、有趣、大家都開心",
                "type": [5, 10]
            },
            {
                "reason_en": "Bicker signify decent affections of each other",
                "reason_cn": "鬥嘴表示感情好",
                "type": [4, 10]
            },
            {
                "reason_en": "Help others get into the topic",
                "reason_cn": "幫助他人融入話題",
                "type": [4, 11]
            },
            {
                "reason_en": "Feeling Humorous, hilarious",
                "reason_cn": "幽默、好笑",
                "type": 5
            },
            {
                "reason_en": "Help others change moods",
                "reason_cn": "幫助他人轉換心情",
                "type": 4
            },
            {
                "reason_en": "Share experiences, instruct others",
                "reason_cn": "分享經驗、教導他人",
                "type": 9
            },
            {
                "reason_en": "Expand connections",
                "reason_cn": "拓展人脈",
                "type": [8, 10]
            },
            {
                "reason_en": "The actions of keeping telling and reminding others imply the concerns",
                "reason_cn": "關心所以會不斷交代、提醒他人",
                "type": "7、9"
            },
            {
                "reason_en": "Collect and exchange commercial information and the fluctuations of financial market",
                "reason_cn": "收集、交流商業資訊與市場脈動",
                "type": 8
            },
            {
                "reason_en": "Happiness and keep laughing often can enhance physical and mental health",
                "reason_cn": "快樂、笑口常開可以提升身心健康",
                "type": [2, 12]
            }
        ]
    },
    {
        "id": 40,
        "name_cn": "幫忙",
        "description_cn": "當小幫手",
        "name_en": "Helper",
        "description_en": "Be a little helper",
        "reason": [
            {
                "reason_en": "Love helping others and feel sense of achievement when others are happy",
                "reason_cn": "喜歡協助他人，看別人開心有成就感",
                "type": 11
            },
            {
                "reason_en": "Improve self-capability, or instruct others",
                "reason_cn": "喜歡提升自己的能力，或指導他人",
                "type": 9
            },
            {
                "reason_en": "Love to give and contribute",
                "reason_cn": "喜歡付出與貢獻",
                "type": 11
            },
            {
                "reason_en": "Love interacting with people",
                "reason_cn": "喜歡與人互動",
                "type": [4, 11]
            },
            {
                "reason_en": "Enjoy the sense of participation or appraisal and gratitude",
                "reason_cn": "喜歡有參與感，或被稱讚、感激",
                "type": 10
            },
            {
                "reason_en": "Feel self-value",
                "reason_cn": "感覺到自我的價值",
                "type": 2
            },
            {
                "reason_en": "People should help each other, helping others is the essence of happiness",
                "reason_cn": "人就是要互相幫助，助人為快樂之本",
                "type": [4, 11]
            },
            {
                "reason_en": "In exchange for pocket money or real rewards",
                "reason_cn": "換取零用錢或實質回饋",
                "type": 8
            },
            {
                "reason_en": "Like to express love with actions",
                "reason_cn": "喜歡用行動表達關愛",
                "type": 6
            },
            {
                "reason_en": "Love to help others plan or organize",
                "reason_cn": "喜歡幫別人規劃或整理",
                "type": 7
            },
            {
                "reason_en": "Enjoy hands-on participation",
                "reason_cn": "喜歡動手參與",
                "type": 6
            },
            {
                "reason_en": "Studying and thinking of how to solve problems",
                "reason_cn": "研究與思考如何解決問題",
                "type": 1
            },
            {
                "reason_en": "Making efforts for environmental protection and the earth",
                "reason_cn": "做環保為地球盡一份心力",
                "type": 12
            },
            {
                "reason_en": "Sometimes gain comes from losing",
                "reason_cn": "吃虧就是占便宜",
                "type": 11
            },
            {
                "reason_en": "Just make a small favor",
                "reason_cn": "舉手之勞而已",
                "type": 11
            }
        ]
    },
    {
        "id": 41,
        "name_cn": "環保",
        "description_cn": "做環保",
        "name_en": "Environmental protection",
        "description_en": "Engaging in environmental protection",
        "reason": [
            {
                "reason_en": "Environmental protection is important",
                "reason_cn": "環保很重要",
                "type": 12
            },
            {
                "reason_en": "Nature is beautiful and must show appreciation",
                "reason_cn": "大自然很美麗，要珍惜",
                "type": 12
            },
            {
                "reason_en": "Resources are limited and must consider sustainability",
                "reason_cn": "資源有限，要永續經營",
                "type": 12
            },
            {
                "reason_en": "It is a responsibility",
                "reason_cn": "是一種責任",
                "type": [6, 7]
            },
            {
                "reason_en": "Life in cycle, but there is only one earth",
                "reason_cn": "生命會循環，地球只有一個",
                "type": 2
            },
            {
                "reason_en": "People are interconnected with the environment",
                "reason_cn": "人與環境息息相關",
                "type": 12
            },
            {
                "reason_en": "Good environment means good health",
                "reason_cn": "環境好，身體就健康",
                "type": 12
            },
            {
                "reason_en": "More economical",
                "reason_cn": "經濟實惠",
                "type": 6
            },
            {
                "reason_en": "A habit",
                "reason_cn": "一種習慣",
                "type": 7
            },
            {
                "reason_en": "A trend",
                "reason_cn": "流行趨勢",
                "type": 5
            },
            {
                "reason_en": "Humans are the dominant leader of earth",
                "reason_cn": "人類是地球的主導者",
                "type": 9
            },
            {
                "reason_en": "Business opportunity",
                "reason_cn": "有商機",
                "type": 8
            },
            {
                "reason_en": "Innovative creativity for recycled objects",
                "reason_cn": "回收物可以發揮創意",
                "type": 3
            },
            {
                "reason_en": "Animal and plant conservation",
                "reason_cn": "保育動植物",
                "type": 12
            },
            {
                "reason_en": "Learn or study new method for promoting environmental protection",
                "reason_cn": "學習或研究新方法來促進環保",
                "type": 1
            },
            {
                "reason_en": "Resources should be shared fairly with all the livings on earth",
                "reason_cn": "資源應該與地球萬物公平的共享",
                "type": 12
            },
            {
                "reason_en": "Economize or energy saving means saving money",
                "reason_cn": "節約、節能就是省錢",
                "type": [6, 7]
            },
            {
                "reason_en": "Making efforts for the earth",
                "reason_cn": "為地球盡一份心力",
                "type": 11
            },
            {
                "reason_en": "For the next generation",
                "reason_cn": "為了下一代好",
                "type": 4
            }
        ]
    },
    {
        "id": 42,
        "name_cn": "植物",
        "description_cn": "親近大自然/\r\n欣賞植物/種植物\r",
        "name_en": "Plants \r",
        "description_en": "Get close to the nature/Admiring and growing plants",
        "reason": [
            {
                "reason_en": "Love the nature",
                "reason_cn": "喜歡大自然",
                "type": 12
            },
            {
                "reason_en": "Love plants",
                "reason_cn": "喜歡植物",
                "type": 12
            },
            {
                "reason_en": "Enjoy caring for plants",
                "reason_cn": "喜歡照顧植物",
                "type": 12
            },
            {
                "reason_en": "Beautify environment",
                "reason_cn": "美化環境",
                "type": 3
            },
            {
                "reason_en": "Love to have plants around",
                "reason_cn": "喜歡植物陪伴",
                "type": 12
            },
            {
                "reason_en": "Care about environmental conservation issues",
                "reason_cn": "關心環境保育議題",
                "type": 12
            },
            {
                "reason_en": "Enjoy do things by self",
                "reason_cn": "喜歡動手做",
                "type": 6
            },
            {
                "reason_en": "Love the openness and the comfort of free air",
                "reason_cn": "喜歡開闊、透透氣、舒暢",
                "type": 5
            },
            {
                "reason_en": "Love floral design and the art of potted plants",
                "reason_cn": "喜歡花藝、盆栽藝術",
                "type": 3
            },
            {
                "reason_en": "Cultivating temperament",
                "reason_cn": "陶冶性情",
                "type": 2
            },
            {
                "reason_en": "No interaction with other people required",
                "reason_cn": "可以不用跟人有互動",
                "type": [6, 7]
            },
            {
                "reason_en": "A sense of liveliness",
                "reason_cn": "有生命力的感覺",
                "type": 12
            },
            {
                "reason_en": "Promoting health",
                "reason_cn": "促進健康",
                "type": 12
            },
            {
                "reason_en": "Love to research on caring and cultivating methods",
                "reason_cn": "喜歡研究照顧與培育方式",
                "type": 1
            },
            {
                "reason_en": "Business opportunity (monetary gain)",
                "reason_cn": "有商機(可以賺錢)",
                "type": 8
            },
            {
                "reason_en": "It`s economical to grow vegetables and fruits by self",
                "reason_cn": "自己種蔬果很經濟實惠",
                "type": 6
            },
            {
                "reason_en": "Enjoy learning about plants",
                "reason_cn": "喜歡學習植物知識",
                "type": 1
            },
            {
                "reason_en": "Engage or volunteer in environmental related public welfare activities",
                "reason_cn": "會從事環境相關的公益活動或志工",
                "type": 12
            },
            {
                "reason_en": "Beautify environment to serve others",
                "reason_cn": "美化環境服務他人",
                "type": 11
            },
            {
                "reason_en": "Looking plants growing gradually brings a sense of steadiness",
                "reason_cn": "看著植物逐漸成長很踏實",
                "type": 6
            }
        ]
    },
    {
        "id": 43,
        "name_cn": "強身",
        "description_cn": "養生/練功/太極拳\r",
        "name_en": "Physical fitness",
        "description_en": "Health preservation / Exercise /Taichi\r",
        "reason": [
            {
                "reason_en": "Maintain health",
                "reason_cn": "維持健康",
                "type": 12
            },
            {
                "reason_en": "Mind and body are one",
                "reason_cn": "身心靈是一體的",
                "type": 2
            },
            {
                "reason_en": "Becoming stronger",
                "reason_cn": "變強壯",
                "type": 9
            },
            {
                "reason_en": "Physical activities",
                "reason_cn": "活動身體",
                "type": 5
            },
            {
                "reason_en": "Health is wealth",
                "reason_cn": "健康就是財富",
                "type": 8
            },
            {
                "reason_en": "A habit",
                "reason_cn": "一種習慣",
                "type": 7
            },
            {
                "reason_en": "Collecting and organizing information on health and preservation",
                "reason_cn": "蒐集整理養生、健康的資訊",
                "type": 7
            },
            {
                "reason_en": "Simple ordinary life",
                "reason_cn": "平凡簡單的生活",
                "type": [6, 7]
            },
            {
                "reason_en": "Replenish nourishment to body for health preservation",
                "reason_cn": "調養氣息、調養身體",
                "type": 12
            },
            {
                "reason_en": "The law of body function and the return to natural way",
                "reason_cn": "身體運行的法則，回歸大自然",
                "type": 12
            },
            {
                "reason_en": "More economical",
                "reason_cn": "經濟實惠",
                "type": [6, 8]
            },
            {
                "reason_en": "Business opportunity",
                "reason_cn": "有商機",
                "type": 8
            },
            {
                "reason_en": "Able to help others",
                "reason_cn": "可以幫助別人",
                "type": [4, 11]
            },
            {
                "reason_en": "Recommending some health products or courses to others",
                "reason_cn": "推薦他人養生的產品、課程",
                "type": 10
            },
            {
                "reason_en": "Learning new skill or knowledge",
                "reason_cn": "學習新技能或知識",
                "type": 1
            },
            {
                "reason_en": "Studying way of health preservation",
                "reason_cn": "鑽研養身的方法",
                "type": 1
            },
            {
                "reason_en": "For prolong life",
                "reason_cn": "可以延年益壽",
                "type": 12
            },
            {
                "reason_en": "Engage or volunteer in environmental related public welfare activities",
                "reason_cn": "會從事環境相關的公益活動或志工",
                "type": 12
            },
            {
                "reason_en": "Beautify environment to serve others",
                "reason_cn": "美化環境服務他人",
                "type": 11
            },
            {
                "reason_en": "Looking plants growing gradually brings a sense of steadiness",
                "reason_cn": "看著植物逐漸成長很踏實",
                "type": 6
            }
        ]
    },
    {
        "id": 44,
        "name_cn": "郊遊",
        "description_cn": "戶外活動、野餐、露營、爬山、玩水、釣魚、溯溪",
        "name_en": "Outdoor activities",
        "description_en": "Outdoor activities, picnics, camping, mountain climbing, water sports, fishing, river tracing",
        "reason": [
            {
                "reason_en": "Get close to and experience the nature",
                "reason_cn": "親近、感受大自然",
                "type": 12
            },
            {
                "reason_en": "In pursuit of health",
                "reason_cn": "追求健康",
                "type": 12
            },
            {
                "reason_en": "Be together with friends",
                "reason_cn": "與朋友一起",
                "type": 4
            },
            {
                "reason_en": "A sense of belonging in group",
                "reason_cn": "有團體歸屬感",
                "type": 4
            },
            {
                "reason_en": "Physical activities",
                "reason_cn": "活動身體",
                "type": 5
            },
            {
                "reason_en": "Freedom, novelty, fun and excitement",
                "reason_cn": "自由、新奇、好玩、暢快",
                "type": 5
            },
            {
                "reason_en": "Admiring the beauty of nature",
                "reason_cn": "欣賞大自然的美",
                "type": 3
            },
            {
                "reason_en": "In pursuit of mental peace",
                "reason_cn": "追求心情平靜",
                "type": 2
            },
            {
                "reason_en": "Feel the power of nature",
                "reason_cn": "感受大自然的能量",
                "type": 2
            },
            {
                "reason_en": "Use natural materials to make things",
                "reason_cn": "運用大自然的素材動手做東西",
                "type": 6
            },
            {
                "reason_en": "Going out alone without interacting with other people",
                "reason_cn": "自己去走走，不用和人互動",
                "type": [6, 7]
            },
            {
                "reason_en": "Return to the nature and away from technologies",
                "reason_cn": "回歸自然狀態，遠離科技",
                "type": 12
            },
            {
                "reason_en": "Learn relevant knowledge about the nature",
                "reason_cn": "學習跟大自然有關的知識",
                "type": 1
            },
            {
                "reason_en": "Participate in family activities",
                "reason_cn": "參與家庭活動",
                "type": 4
            },
            {
                "reason_en": "Broaden the knowledge",
                "reason_cn": "增廣見聞",
                "type": 1
            },
            {
                "reason_en": "Expanding connections",
                "reason_cn": "拓展人脈",
                "type": [8, 10]
            },
            {
                "reason_en": "Group outing",
                "reason_cn": "揪團出遊",
                "type": [9, 10]
            },
            {
                "reason_en": "Love to interact with animals and plants",
                "reason_cn": "喜歡與動植物相處",
                "type": 12
            },
            {
                "reason_en": "Accompany others to go out",
                "reason_cn": "陪伴他人出遊",
                "type": 11
            },
            {
                "reason_en": "Help prepare things or foods for activities",
                "reason_cn": "幫忙預備張羅外出用品或食材",
                "type": 11
            },
            {
                "reason_en": "Make schedule for the day, make material lists",
                "reason_cn": "規劃好一天的行程，寫好物品清單",
                "type": 7
            },
            {
                "reason_en": "Complete the journey step by step brings a sense of steadiness",
                "reason_cn": "一步一腳印完成路程很踏實",
                "type": 6
            },
            {
                "reason_en": "Being with self calmly",
                "reason_cn": "安靜的和自己相處",
                "type": 2
            }
        ]
    },
    {
        "id": 45,
        "name_cn": "動物",
        "description_cn": "餵養動物/和動物互動",
        "name_en": "Animals ",
        "description_en": "Raising & interacting with animals",
        "reason": [
            {
                "reason_en": "Love animals",
                "reason_cn": "喜歡動物",
                "type": 12
            },
            {
                "reason_en": "Enjoy caring for animals",
                "reason_cn": "喜歡照顧動物",
                "type": [11, 12]
            },
            {
                "reason_en": "Love to interact with animals",
                "reason_cn": "喜歡和動物互動",
                "type": 12
            },
            {
                "reason_en": "Love to have animals around",
                "reason_cn": "喜歡有動物陪伴",
                "type": 12
            },
            {
                "reason_en": "Care about environmental conservation issues",
                "reason_cn": "關心環境保育議題",
                "type": 12
            },
            {
                "reason_en": "Animals are cute and therapeutic (satisfactory)",
                "reason_cn": "動物很可愛、很療癒",
                "type": 12
            },
            {
                "reason_en": "Cultivating temperament",
                "reason_cn": "陶冶性情",
                "type": 2
            },
            {
                "reason_en": "Find it complicated and annoying to interact with other people",
                "reason_cn": "和人互動很複雜很麻煩",
                "type": [6, 7]
            },
            {
                "reason_en": "A sense of liveliness",
                "reason_cn": "有生命力的感覺",
                "type": 12
            },
            {
                "reason_en": "Promoting health",
                "reason_cn": "促進健康",
                "type": 12
            },
            {
                "reason_en": "Love animals and care them like family",
                "reason_cn": "喜歡寵愛動物，提供像對待親友一樣的照顧",
                "type": [4, 11]
            },
            {
                "reason_en": "Love to research on caring methods",
                "reason_cn": "喜歡研究照顧方式",
                "type": 1
            },
            {
                "reason_en": "Business opportunity (monetary gain)",
                "reason_cn": "有商機(可以賺錢)",
                "type": 8
            },
            {
                "reason_en": "Animals are obedient and easy to control",
                "reason_cn": "動物聽話、好掌控",
                "type": 9
            },
            {
                "reason_en": "Enjoy learning about animals",
                "reason_cn": "喜歡學習動物知識",
                "type": 1
            },
            {
                "reason_en": "Love to construct home, make clothes or other things for animals",
                "reason_cn": "喜歡動手打造動物的住家、縫製衣服，或製作周邊相關物品",
                "type": 6
            },
            {
                "reason_en": "Engage or volunteer in animal related public welfare activities",
                "reason_cn": "會從事動物的公益活動或志工",
                "type": 12
            },
            {
                "reason_en": "Animals are simple and kind",
                "reason_cn": "動物比較單純善良",
                "type": 12
            },
            {
                "reason_en": "It’s a responsibility to take care of animals",
                "reason_cn": "照顧動物是一種責任",
                "type": 6
            },
            {
                "reason_en": "Take good care of everything necessary for the animal",
                "reason_cn": "仔細地打理動物所需要的一切",
                "type": [7, 11]
            },
            {
                "reason_en": "It is worthy to devote oneself to animals",
                "reason_cn": "為動物付出很值得",
                "type": 11
            },
            {
                "reason_en": "Love to build the house for animals, sews clothing or make related products",
                "reason_cn": "喜歡動手打造動物的住家、\n縫製衣服,或製作周邊相關物品",
                "type": 6
            }
        ]
    },
    {
        "id": 46,
        "name_cn": "宅",
        "description_cn": "待在家\r",
        "name_en": "Homebody (Otaku)",
        "description_en": "Staying at home",
        "reason": [
            {
                "reason_en": "Don’t want to interact with people",
                "reason_cn": "不想與人互動",
                "type": [6, 7]
            },
            {
                "reason_en": "Love routines and a life with few changes",
                "reason_cn": "喜歡規律、變化性小的生活",
                "type": 7
            },
            {
                "reason_en": "Enjoy doing nothing",
                "reason_cn": "喜歡享受無所事事",
                "type": 5
            },
            {
                "reason_en": "Love being alone and LOHAS",
                "reason_cn": "喜歡獨處、慢活",
                "type": 2
            },
            {
                "reason_en": "Able to spend time with family",
                "reason_cn": "可以與家人在一起",
                "type": 4
            },
            {
                "reason_en": "Love house life (being comfortable at home)",
                "reason_cn": "喜歡居家生活(在家很舒服)",
                "type": [6, 7]
            },
            {
                "reason_en": "Reduce expenses",
                "reason_cn": "減少花費",
                "type": [6, 8]
            },
            {
                "reason_en": "Doing house chores",
                "reason_cn": "整理家務",
                "type": [6, 7]
            },
            {
                "reason_en": "Love simplicity or simply do not want to think",
                "reason_cn": "喜歡簡單平凡，或不想動腦",
                "type": [6, 7]
            },
            {
                "reason_en": "Able to direct personal time and space at will",
                "reason_cn": "可以主導自己的時間/空間",
                "type": 9
            },
            {
                "reason_en": "Love peaceful and concentrated thinking",
                "reason_cn": "喜歡安靜專注的思考",
                "type": 1
            },
            {
                "reason_en": "Replenish rest and sleep; Value health",
                "reason_cn": "補眠休息，重視健康",
                "type": 12
            },
            {
                "reason_en": "Reduce chance to go out, enhance health",
                "reason_cn": "減少外出，提升健康",
                "type": 12
            },
            {
                "reason_en": "Just want to do what I enjoy doing",
                "reason_cn": "只想做自己喜歡的事情",
                "type": [5, 9]
            },
            {
                "reason_en": "Develop freelance or work from home",
                "reason_cn": "發展在家工作或自由業",
                "type": [8, 9]
            },
            {
                "reason_en": "It`s annoying to go out",
                "reason_cn": "外出很麻煩",
                "type": 5
            },
            {
                "reason_en": "Able to do .... activities (Please refer to “other activities” section)",
                "reason_cn": "可以做…活動 (請參見其他種類的活動)",
                "type": 0,
                "card_id_redirection": 0,
            }
        ]
    },
    {
        "id": 47,
        "name_cn": "電玩",
        "description_cn": "打電動/打怪/手機遊戲\r",
        "name_en": "Electronic gaming",
        "description_en": "Video gaming/Phone games\r",
        "reason": [
            {
                "reason_en": "Immediate achievement with simple screen strokes",
                "reason_cn": "自己動手敲、打、滑，且立即有成果",
                "type": 6
            },
            {
                "reason_en": "Contemplating (puzzle games)",
                "reason_cn": "動腦思考（益智遊戲）",
                "type": 1
            },
            {
                "reason_en": "Admiring the videos, sounds and plots",
                "reason_cn": "欣賞影像、聲音、劇情",
                "type": 3
            },
            {
                "reason_en": "Teamwork in gaming to fight enemies",
                "reason_cn": "跟他人聯手打怪",
                "type": 4
            },
            {
                "reason_en": "Stand-alone solo games",
                "reason_cn": "可以不用跟人互動的遊戲",
                "type": [6, 7]
            },
            {
                "reason_en": "Feel excited when fighting enemies in games",
                "reason_cn": "打怪很爽快、很刺激",
                "type": 5
            },
            {
                "reason_en": "Get lots of imaginary possibilities in virtual world",
                "reason_cn": "虛擬世界有很多想像空間",
                "type": 2
            },
            {
                "reason_en": "Enjoy multi-tasking at the same time",
                "reason_cn": "享受同時間做很多事",
                "type": [5, 6]
            },
            {
                "reason_en": "Inviting different occupation roles and making appointments",
                "reason_cn": "邀請不同職業角色、約時間",
                "type": 4
            },
            {
                "reason_en": "Can lead and unify as team",
                "reason_cn": "可以領導、統合",
                "type": 9
            },
            {
                "reason_en": "Discuss work assignment and monetary reward",
                "reason_cn": "討論工作及金錢分配",
                "type": 8
            },
            {
                "reason_en": "Discuss strategy and research to beat the game",
                "reason_cn": "討論戰略、研究如何破關",
                "type": 1
            },
            {
                "reason_en": "Get to know new acquaintance",
                "reason_cn": "認識新朋友",
                "type": [4, 10]
            },
            {
                "reason_en": "Help others to level up",
                "reason_cn": "幫別人練等級",
                "type": 11
            },
            {
                "reason_en": "Accompany others in gaming",
                "reason_cn": "陪伴他人遊戲",
                "type": 11
            },
            {
                "reason_en": "Negotiate and trade gaming items",
                "reason_cn": "商議配備買賣",
                "type": [8, 10]
            },
            {
                "reason_en": "Challenge difficulties and record breaking",
                "reason_cn": "挑戰難關、刷新紀錄",
                "type": 9
            },
            {
                "reason_en": "Actively recommending games and items to others",
                "reason_cn": "主動推薦遊戲、配備給其他人",
                "type": 10
            },
            {
                "reason_en": "Free, happy and no seriousness",
                "reason_cn": "自由、快樂、不用太認真",
                "type": 5
            },
            {
                "reason_en": "Love simplicity without much thinking",
                "reason_cn": "喜歡簡單、單純，不需要太動腦",
                "type": [6, 7]
            },
            {
                "reason_en": "Improve brain reflexes and prevent from aging",
                "reason_cn": "增加大腦反應速度，預防退化",
                "type": 12
            },
            {
                "reason_en": "Increase physical/bodily activity",
                "reason_cn": "增加身體活動量",
                "type": [5, 12]
            },
            {
                "reason_en": "Leveling or upgrading equipments to earn points or money",
                "reason_cn": "練等級或裝備可以賺取點數或金錢",
                "type": 8
            },
            {
                "reason_en": "Create the world that I desire",
                "reason_cn": "創造自己想要的世界",
                "type": [3, 9]
            }
        ]
    },
    {
        "id": 48,
        "name_cn": "旅行",
        "description_cn": "旅遊/背包客",
        "name_en": "Traveling",
        "description_en": "Traveling/Backpacker",
        "reason": [
            {
                "reason_en": "Get to know new acquaintance",
                "reason_cn": "認識新朋友",
                "type": [4, 10]
            },
            {
                "reason_en": "To be together with family and friends",
                "reason_cn": "和親友一起",
                "type": [4, 10, 11]
            },
            {
                "reason_en": "Love planning itinerary",
                "reason_cn": "喜歡仔細規劃行程",
                "type": 7
            },
            {
                "reason_en": "Enjoy seeing different cities and architectures",
                "reason_cn": "喜歡看不同城市、建築",
                "type": 3
            },
            {
                "reason_en": "Love different cultures and historical heritages",
                "reason_cn": "喜歡不同文化、歷史古蹟",
                "type": 3
            },
            {
                "reason_en": "Enjoy different cultural atmospheres",
                "reason_cn": "享受不同人文氣息",
                "type": [2, 3]
            },
            {
                "reason_en": "Value life quality",
                "reason_cn": "重視生活品質",
                "type": 5
            },
            {
                "reason_en": "Attempt of new things",
                "reason_cn": "嘗試新事物",
                "type": 5
            },
            {
                "reason_en": "Choose the itinerary of the best value (cost performance ratio)",
                "reason_cn": "選擇最佳CP值的行程",
                "type": 8
            },
            {
                "reason_en": "Settling spirituality and engage in inner self dialogue",
                "reason_cn": "沉澱心靈、跟自己對話",
                "type": 2
            },
            {
                "reason_en": "Love the nature",
                "reason_cn": "喜歡大自然",
                "type": 12
            },
            {
                "reason_en": "Love to contact the locals to hear different stories",
                "reason_cn": "喜歡和當地人接觸、聽不同的故事",
                "type": 4
            },
            {
                "reason_en": "Enjoy new and exciting adventures",
                "reason_cn": "享受、新奇、冒險",
                "type": 5
            },
            {
                "reason_en": "Enjoy being active and walking",
                "reason_cn": "喜歡行動力、雙腳行走",
                "type": 6
            },
            {
                "reason_en": "Broaden the knowledge",
                "reason_cn": "增廣見聞",
                "type": 1
            },
            {
                "reason_en": "Learn language, knowledge and skills",
                "reason_cn": "學習語言或知識技能",
                "type": 1
            },
            {
                "reason_en": "Being free and happy is relaxing",
                "reason_cn": "自由、玩樂很紓壓",
                "type": 5
            },
            {
                "reason_en": "Experience life and self-growth",
                "reason_cn": "體驗人生、自我成長",
                "type": 2
            },
            {
                "reason_en": "Be a local volunteer",
                "reason_cn": "當地志工",
                "type": 4
            },
            {
                "reason_en": "Self-challenging and realize the dream",
                "reason_cn": "自我挑戰、實現夢想",
                "type": 9
            },
            {
                "reason_en": "Love shopping and foods",
                "reason_cn": "喜歡購物、美食",
                "type": 5
            },
            {
                "reason_en": "Surrogate shopping and business opportunity",
                "reason_cn": "代購、商機",
                "type": [8, 10]
            },
            {
                "reason_en": "Compile practical traveling information for others as reference",
                "reason_cn": "寫實用的旅遊資訊提供他人參考",
                "type": 11
            },
            {
                "reason_en": "Recommending good sightseeing to friends and relatives",
                "reason_cn": "推薦景點給親友",
                "type": 10
            },
            {
                "reason_en": "Budget travel: travel with the most economical way",
                "reason_cn": "窮遊：用最省旅費的方式進行",
                "type": [6, 7]
            },
            {
                "reason_en": "Help prepare things or foods for going out",
                "reason_cn": "幫忙預備外出用品或食材",
                "type": 11
            },
            {
                "reason_en": "Love taking pictures (please refer to “photographing” section)",
                "reason_cn": "喜歡拍照 (請參考「拍攝」活動)",
                "type": 0,
                "card_id_redirection": 11,
            }
        ]
    },
    {
        "id": 49,
        "name_cn": "聽歌",
        "description_cn": "聽音樂",
        "name_en": "Music listening",
        "description_en": "Music listening",
        "reason": [
            {
                "reason_en": "Imagine self as a singer or performer",
                "reason_cn": "想像自己是歌手、演奏者…等",
                "type": 9
            },
            {
                "reason_en": "Be helpful for thinking and concentrating",
                "reason_cn": "幫助思考、增加專注",
                "type": 1
            },
            {
                "reason_en": "Love to sing along",
                "reason_cn": "喜歡跟著哼唱",
                "type": 3
            },
            {
                "reason_en": "Learn language",
                "reason_cn": "學習語言",
                "type": 1
            },
            {
                "reason_en": "Share and exchange with others, based on a common topic of discussion",
                "reason_cn": "和他人分享、交流，有共同話題",
                "type": [4, 10, 11]
            },
            {
                "reason_en": "Religious music, meditation, mind rest and light music",
                "reason_cn": "宗教音樂、冥想、靜心、輕音樂",
                "type": 2
            },
            {
                "reason_en": "Admiring rhythm and melody (such as classical music)",
                "reason_cn": "欣賞弦律、樂章（例：古典樂）",
                "type": 3
            },
            {
                "reason_en": "Put on the earphones to get away from the world without interacting with other people",
                "reason_cn": "戴上耳機隔絕世界不用和人互動",
                "type": [6, 7]
            },
            {
                "reason_en": "Learn to sing or perform",
                "reason_cn": "學習歌唱或演奏",
                "type": 3
            },
            {
                "reason_en": "Pondering about the packaging and marketing of album to see market response",
                "reason_cn": "思考專輯的包裝、行銷方式、市場反應",
                "type": 8
            },
            {
                "reason_en": "Express emotions and gain peace of mind",
                "reason_cn": "抒發情感、讓心情平靜",
                "type": 2
            },
            {
                "reason_en": "Searching for creative inspiration",
                "reason_cn": "找尋各式創作靈感",
                "type": 3
            },
            {
                "reason_en": "Recommend and persuade others to listen",
                "reason_cn": "推薦、說服大家來聆聽",
                "type": 10
            },
            {
                "reason_en": "Enjoy repeated melody, quite catchy",
                "reason_cn": "喜歡反覆弦律很洗腦",
                "type": [6, 7]
            },
            {
                "reason_en": "Listening to natural music",
                "reason_cn": "聆聽大自然音樂",
                "type": 12
            },
            {
                "reason_en": "Admiring and commenting on music",
                "reason_cn": "鑑賞、評論音樂",
                "type": 3
            },
            {
                "reason_en": "Music as stimulus to relieve emotions, quite satisfactory",
                "reason_cn": "音樂的刺激可以發洩情緒很痛快",
                "type": 5
            },
            {
                "reason_en": "Pursue (chasing drama, stars, animation) and listening to music",
                "reason_cn": "追求……（追劇、追星、追動漫）而聽音樂",
                "type": 5
            },
            {
                "reason_en": "Promoting physical and mental health",
                "reason_cn": "促進身心健康",
                "type": 12
            },
            {
                "reason_en": "Provide musical resources to everyone",
                "reason_cn": "提供音樂資源分享給大家",
                "type": 11
            }
        ]
    },
    {
        "id": 50,
        "name_cn": "節目",
        "description_cn": "看電視/數位電視/聽廣播/網路頻道",
        "name_en": "Shows",
        "description_en": "Watching television/Digital TV/Listening to broadcast/Internet channels\r",
        "reason": [
            {
                "reason_en": "Shows of religion, spirituality and astrology",
                "reason_cn": "宗教、心靈、星座命理節目",
                "type": 2
            },
            {
                "reason_en": "Puzzle game or educational show, requiring thinking",
                "reason_cn": "益智節目、動腦思考、教學節目",
                "type": 1
            },
            {
                "reason_en": "Enjoy being free from interaction with others",
                "reason_cn": "喜歡不需要跟人互動",
                "type": [6, 7]
            },
            {
                "reason_en": "Learn and broaden new knowledge",
                "reason_cn": "學習新知、增廣見聞",
                "type": 1
            },
            {
                "reason_en": "Sports events, various physical competitions",
                "reason_cn": "運動賽事、各種體能競賽",
                "type": 5
            },
            {
                "reason_en": "Humanities, art, music, opera programs",
                "reason_cn": "人文、藝術、音樂、戲曲節目",
                "type": 3
            },
            {
                "reason_en": "Intellectual conversation, inspirational program, microcosm of lives",
                "reason_cn": "知性談話性、勵志節目、人生劇場",
                "type": 4
            },
            {
                "reason_en": "Drama series, idol drama",
                "reason_cn": "連續劇、偶像劇",
                "type": 5
            },
            {
                "reason_en": "Science, logics shows",
                "reason_cn": "科學、邏輯推理節目",
                "type": 1
            },
            {
                "reason_en": "Variety shows of entertainment, fashion, beauty and makeup, food and fun, etc.",
                "reason_cn": "綜藝娛樂、流行時尚、美妝穿搭、美食玩樂…等節目",
                "type": 5
            },
            {
                "reason_en": "News and political shows",
                "reason_cn": "新聞、政論",
                "type": [4, 9]
            },
            {
                "reason_en": "Animal channel, natural and ecological environment program",
                "reason_cn": "動物頻道、自然環境生態",
                "type": 12
            },
            {
                "reason_en": "Health programs",
                "reason_cn": "健康節目",
                "type": 12
            },
            {
                "reason_en": "Capable of discussing and sharing with others",
                "reason_cn": "可以和他人討論、交流或一起分享",
                "type": [4, 10]
            },
            {
                "reason_en": "Hilarious, funny programs",
                "reason_cn": "搞笑、好玩的節目",
                "type": 5
            },
            {
                "reason_en": "Love simplicity without much thinking",
                "reason_cn": "喜歡簡單、單純，不需要太動腦",
                "type": [6, 7]
            },
            {
                "reason_en": "Actively recommending good shows to others",
                "reason_cn": "主動推薦好的節目給其他人",
                "type": 10
            },
            {
                "reason_en": "Life & practical shows",
                "reason_cn": "生活化、實用的節目",
                "type": 6
            },
            {
                "reason_en": "Financial, shopping and business information",
                "reason_cn": "財經、購物、商業資訊",
                "type": 8
            },
            {
                "reason_en": "Observe fashion trends and the fluctuations of financial market",
                "reason_cn": "觀察流行與市場脈動",
                "type": 8
            },
            {
                "reason_en": "Accompany others to watch programs",
                "reason_cn": "陪伴他人看節目",
                "type": 11
            },
            {
                "reason_en": "Watch programs for following idols, internet celebrities, program hosts etc.",
                "reason_cn": "追隨…（偶像、網紅、主持人）而看節目",
                "type": 5
            },
            {
                "reason_en": "Videos or movies (please referred to “watching movies” section)",
                "reason_cn": "影集、電影(請參考「電影」活動)",
                "type": 0,
                "card_id_redirection": 52,
            },
            {
                "reason_en": "Cartoons or animations (please referred to “animations” section)",
                "reason_cn": "卡通、動漫(請參考「漫畫」活動)",
                "type": 0,
                "card_id_redirection": 53,
            },
            {
                "reason_en": "Cartoons or animations (please referred to “animations” section)",
                "reason_cn": "卡通、動漫(請參考「漫畫」活動)",
                "type": 0,
                "card_id_redirection": 53,
            }
        ]
    },
    {
        "id": 51,
        "name_cn": "放空",
        "description_cn": "發呆/睡覺/甚麼都不做",
        "name_en": "Stare out",
        "description_en": "Daze/Sleep/Do nothing",
        "reason": [
            {
                "reason_en": "Rest, have fun",
                "reason_cn": "休息，享樂",
                "type": 5
            },
            {
                "reason_en": "Value sleeping and enjoy the sense of adequate sleep",
                "reason_cn": "重視睡眠，喜歡睡飽的感覺",
                "type": 5
            },
            {
                "reason_en": "Completely relaxing when zoning out",
                "reason_cn": "放空很舒服",
                "type": 5
            },
            {
                "reason_en": "Love fantasizing and daydreaming",
                "reason_cn": "喜歡幻想、白日夢",
                "type": [2, 3]
            },
            {
                "reason_en": "Value spiritual break and life of LOHAS",
                "reason_cn": "重視心靈的休息、慢活",
                "type": 2
            },
            {
                "reason_en": "Value health",
                "reason_cn": "重視健康",
                "type": 12
            },
            {
                "reason_en": "Don’t want to interact with people",
                "reason_cn": "不想與人互動",
                "type": [6, 7]
            },
            {
                "reason_en": "Love simple routine life or don’t want to contemplate",
                "reason_cn": "喜歡單純規律的生活，或不想動腦",
                "type": [6, 7]
            },
            {
                "reason_en": "It's fun to sleep and dream",
                "reason_cn": "睡覺做夢很有趣",
                "type": 2
            },
            {
                "reason_en": "Brewing creative inspiration",
                "reason_cn": "醞釀創作靈感",
                "type": 3
            },
            {
                "reason_en": "Feel tired by working hard with brain",
                "reason_cn": "平常一直用腦很疲憊",
                "type": 1
            },
            {
                "reason_en": "Let one reflect oneself calmly",
                "reason_cn": "讓自己安靜地沈澱",
                "type": 2
            },
            {
                "reason_en": "Feel bored and hang around to find something interesting",
                "reason_cn": "無聊閒晃找樂子",
                "type": 5
            },
            {
                "reason_en": "Finally reach the goals and be able to take a rest",
                "reason_cn": "目標終於達成可以休息了",
                "type": 9
            },
            {
                "reason_en": "Rest is for longer journey",
                "reason_cn": "休息是為了走更長遠的路",
                "type": 12
            },
            {
                "reason_en": "Don`t need to care about other people`s feelings or needs",
                "reason_cn": "可以不用顧慮到他人的感受或需求",
                "type": [4, 11]
            },
            {
                "reason_en": "Stop by to have a casual chat",
                "reason_cn": "串門子尬聊",
                "type": 10
            }
        ]
    },
    {
        "id": 52,
        "name_cn": "電影",
        "description_cn": "看電影/看影集/追劇",
        "name_en": "Movies ",
        "description_en": "Seeing movies/series/drama",
        "reason": [
            {
                "reason_en": "Capable of discussing and sharing with others",
                "reason_cn": "可以和他人討論、交流或一起分享",
                "type": [4, 10]
            },
            {
                "reason_en": "Enjoy being free from interaction with others",
                "reason_cn": "喜歡不需要跟人互動",
                "type": [6, 7]
            },
            {
                "reason_en": "Love collecting ticket stubs or movie promotional products",
                "reason_cn": "喜歡蒐集票根、電影宣傳品",
                "type": 7
            },
            {
                "reason_en": "Love writing movie reviews",
                "reason_cn": "喜歡寫影評",
                "type": 3
            },
            {
                "reason_en": "Love to actively recommending good movies to friends and relatives",
                "reason_cn": "喜歡主動推薦好電影給親友",
                "type": 10
            },
            {
                "reason_en": "Research the plot or feature of the work",
                "reason_cn": "研究劇情或作品特色",
                "type": 3
            },
            {
                "reason_en": "Contemplating the director or playwright’s intention",
                "reason_cn": "思考導演/編劇的用意",
                "type": 3
            },
            {
                "reason_en": "Love sharing movie resources to friends and relatives",
                "reason_cn": "喜歡分享電影資源給親友",
                "type": 11
            },
            {
                "reason_en": "Experiencing others` world, life and feeling",
                "reason_cn": "體驗他人的世界、生活、感受",
                "type": 4
            },
            {
                "reason_en": "Suspense, detective, reasoning",
                "reason_cn": "懸疑、偵探、推理類",
                "type": 1
            },
            {
                "reason_en": "Literature and art",
                "reason_cn": "文藝類",
                "type": 3
            },
            {
                "reason_en": "Romance, family, documentary and biography",
                "reason_cn": "愛情、家庭關係、紀錄片、人文傳記",
                "type": 4
            },
            {
                "reason_en": "Erotic art",
                "reason_cn": "情色類",
                "type": 5
            },
            {
                "reason_en": "Comedy",
                "reason_cn": "喜劇、搞笑類",
                "type": 5
            },
            {
                "reason_en": "Action, crime and gangster, war films",
                "reason_cn": "動作、警匪、戰爭類",
                "type": 5
            },
            {
                "reason_en": "Horror, suspense, thriller and bizarre films",
                "reason_cn": "恐怖、驚悚、刺激、詭異類",
                "type": 5
            },
            {
                "reason_en": "Life and death, religion, philosophy, spirituality",
                "reason_cn": "生死、宗教、哲學、靈性類",
                "type": 2
            },
            {
                "reason_en": "Business activities",
                "reason_cn": "商業活動",
                "type": 8
            },
            {
                "reason_en": "Heroes and leadership",
                "reason_cn": "英雄、領袖類",
                "type": 9
            },
            {
                "reason_en": "Plants & animals, environmental protection, ecological environment and health",
                "reason_cn": "動植物、環保、生態、健康類",
                "type": 12
            },
            {
                "reason_en": "Science or knowledge",
                "reason_cn": "科學或知識類",
                "type": 1
            },
            {
                "reason_en": "Sci-fi, fantasy, imagination",
                "reason_cn": "科幻、奇幻、幻想類",
                "type": [2, 5]
            },
            {
                "reason_en": "Accompany others",
                "reason_cn": "陪伴他人",
                "type": 11
            },
            {
                "reason_en": "Seeing movies for following idols, directors, authors and animations",
                "reason_cn": "追隨…（偶像、導演、作家、動漫）而看電影",
                "type": 5
            }
        ]
    },
    {
        "id": 53,
        "name_cn": "漫畫",
        "description_cn": "看漫畫/動漫",
        "name_en": "Comics ",
        "description_en": "Reading comics/animations",
        "reason": [
            {
                "reason_en": "Allow imagination",
                "reason_cn": "有想像空間",
                "type": [2, 3]
            },
            {
                "reason_en": "Admiring the artworks and plots",
                "reason_cn": "喜歡欣賞畫風、畫面、劇情",
                "type": 3
            },
            {
                "reason_en": "Enjoy imitating the artworks",
                "reason_cn": "喜歡動手仿畫",
                "type": 3
            },
            {
                "reason_en": "Enjoy relaxing",
                "reason_cn": "享受與放鬆",
                "type": 5
            },
            {
                "reason_en": "Enjoy exaggerated, funny, or exciting, thrilling pictures",
                "reason_cn": "喜歡誇張、搞笑，或刺激、驚悚的畫面",
                "type": 5
            },
            {
                "reason_en": "Feel passionate, young and hopeful",
                "reason_cn": "喜歡熱血、青春、希望",
                "type": 4
            },
            {
                "reason_en": "Love and romance comics",
                "reason_cn": "愛情、言情漫畫",
                "type": 5
            },
            {
                "reason_en": "No interaction with other people required",
                "reason_cn": "可以不用跟人有互動",
                "type": [6, 7]
            },
            {
                "reason_en": "Love categorizing and collecting comic books",
                "reason_cn": "喜歡把漫畫分類整理，收集漫畫",
                "type": 7
            },
            {
                "reason_en": "Love simplicity without much thinking",
                "reason_cn": "喜歡簡單、單純，不需要太動腦",
                "type": [6, 7]
            },
            {
                "reason_en": "Capable of discussing and sharing with others in interaction",
                "reason_cn": "可以和他人交流互動，或一起分享",
                "type": [4, 10]
            },
            {
                "reason_en": "Actively recommending good comics to friends and relatives",
                "reason_cn": "主動推薦好漫畫給親友",
                "type": 10
            },
            {
                "reason_en": "Actively providing comics to friends and relatives",
                "reason_cn": "主動提供漫畫資源給親友",
                "type": 11
            },
            {
                "reason_en": "Love logic deduction and contemplating",
                "reason_cn": "喜歡邏輯推理、動腦思考",
                "type": 1
            },
            {
                "reason_en": "Enjoy learning new knowledge",
                "reason_cn": "喜歡學習新知",
                "type": 1
            },
            {
                "reason_en": "Related to nature and health issues",
                "reason_cn": "與大自然、健康議題相關",
                "type": 12
            },
            {
                "reason_en": "Reading comics for following authors, voice actors",
                "reason_cn": "追隨…（作者、聲優）而看漫畫",
                "type": 5
            },
            {
                "reason_en": "Love comics with practical information",
                "reason_cn": "喜歡提供具體資訊的漫畫",
                "type": 6
            },
            {
                "reason_en": "Imagine to be the main actor",
                "reason_cn": "想像自己是主角",
                "type": [2, 4]
            }
        ]
    },
    {
        "id": 54,
        "name_cn": "網路",
        "description_cn": "上網/滑手機/直播",
        "name_en": "Internet ",
        "description_en": "Internet Surfing/Phone browsing/Live stream",
        "reason": [
            {
                "reason_en": "Search and learn some information",
                "reason_cn": "吸收資訊，找資料",
                "type": 1
            },
            {
                "reason_en": "Learning or doing assignment and report",
                "reason_cn": "進行學習活動，或做作業、報告",
                "type": 1
            },
            {
                "reason_en": "Broaden the knowledge",
                "reason_cn": "增廣見聞",
                "type": 1
            },
            {
                "reason_en": "Admiring artistic masterpiece (painting, drama, or other creations)",
                "reason_cn": "欣賞藝術作品（畫作、戲劇、創作）",
                "type": 3
            },
            {
                "reason_en": "Regard your relatives and friends, and interact with them frequently",
                "reason_cn": "關心親友動態，時常和親友互動",
                "type": 4
            },
            {
                "reason_en": "Pay attention to fashion, cosmetics, clothing , etc.",
                "reason_cn": "關注時尚、美妝、穿搭…等",
                "type": 5
            },
            {
                "reason_en": "Looking for interesting and novel information",
                "reason_cn": "看新奇、有趣的資訊",
                "type": 5
            },
            {
                "reason_en": "Enjoy being free from interaction with others",
                "reason_cn": "喜歡不需要跟人互動",
                "type": [6, 7]
            },
            {
                "reason_en": "Love online or group shopping, bump into cheap deal",
                "reason_cn": "喜歡網購、團購、撿便宜",
                "type": [5, 7]
            },
            {
                "reason_en": "Pay attention to mainstream information",
                "reason_cn": "關注流行資訊",
                "type": 8
            },
            {
                "reason_en": "Check the amount of likes and followers I get",
                "reason_cn": "查看有多少人注意我，幫我按讚",
                "type": 9
            },
            {
                "reason_en": "Love selfies and upload picture",
                "reason_cn": "喜歡自拍、拍照上傳",
                "type": 9
            },
            {
                "reason_en": "Contact work matters",
                "reason_cn": "聯繫工作要事",
                "type": 9
            },
            {
                "reason_en": "Managing own social group",
                "reason_cn": "經營自己的社群",
                "type": [8, 9]
            },
            {
                "reason_en": "Love to quarrel, chitchat or argue with others",
                "reason_cn": "喜歡和他人鬥嘴、閒聊或辯論",
                "type": 10
            },
            {
                "reason_en": "Enjoy knowing about friends and relatives in silence",
                "reason_cn": "喜歡得知親友訊息，默默參與就好",
                "type": 11
            },
            {
                "reason_en": "Love organizing information and folders",
                "reason_cn": "喜歡把資訊、資料夾分類整理好",
                "type": 7
            },
            {
                "reason_en": "Handling transactional tasks (such as scheduling)",
                "reason_cn": "處理事務性的工作(如:安排行事曆）",
                "type": 7
            },
            {
                "reason_en": "Share own status and feelings",
                "reason_cn": "分享自己的動態與心情",
                "type": 4
            },
            {
                "reason_en": "Love simplicity without much thinking",
                "reason_cn": "喜歡簡單、單純，不需要太動腦",
                "type": [6, 7]
            },
            {
                "reason_en": "Share online hotspots, games and resources with others",
                "reason_cn": "分享網路熱點、遊戲、資源…等給別人",
                "type": 11
            },
            {
                "reason_en": "Pay attention to health information",
                "reason_cn": "留意健康資訊",
                "type": 12
            },
            {
                "reason_en": "Observe business opportunities, managing and marketing ways",
                "reason_cn": "觀察商機、經營行銷方式",
                "type": 8
            },
            {
                "reason_en": "Recommend products of good quality to others",
                "reason_cn": "推薦好物或產品給他人",
                "type": 10
            },
            {
                "reason_en": "Expanding connections",
                "reason_cn": "拓展人脈",
                "type": [8, 10]
            },
            {
                "reason_en": "Read information about fortunetelling, constellation or body and spirit",
                "reason_cn": "看命理、星座或身心靈等資訊",
                "type": 2
            },
            {
                "reason_en": "Earn points or money",
                "reason_cn": "賺取點數或金錢",
                "type": 8
            },
            {
                "reason_en": "Help other people solve problems",
                "reason_cn": "協助他人解決問題",
                "type": 11
            },
            {
                "reason_en": "Able to do…activities (please refer to other activities)",
                "reason_cn": "可以做...活動（請參考其他種類的活動）",
                "type": 0,
                "card_id_redirection": 0,
            }
        ]
    }
]

const SCORES = [
    {
        number: 1,
        type: 1,
        names: 'Investigative',
        listings: [
            'rational', 'objective', 'logical', 'analytical', 'good at problem-solving and strategic thinking', 'intellectual', 'good at reasoning and thinking', 'smart', 'rigorous', 'serious', 'distant from others'
        ],
    },
    {
        number: 2,
        type: 2,
        names: 'Introspective',
        listings: [
            'perceptual', 'highly intuitive', 'thoughtful', 'sensitive', 'contemplative', 'reserved', 'imaginative', 'enjoys exploring the inner self', 'solitude', 'has a strong self-awareness'
        ]
    },
    {
        number: 3,
        type: 3,
        names: 'Creative',
        listings: [
            'rich in emotions', 'creative', 'humorous', 'confident', 'focused', 'artistic', 'romantic', 'aesthetic', 'sees the world differently', 'sentimental'
        ]
    },
    {
        number: 4,
        type: 4,
        names: 'Compassionate',
        listings: [
            'passionate about helping others', 'kind', 'empathetic', 'warm', 'expressive', 'considerate', 'cares for others', 'friendly', 'has good interpersonal skills', 'indecisive'
        ]
    },
    {
        number: 5,
        type: 5,
        names: 'Sensory',
        listings: [
            'straightforward', 'optimistic', 'easy-going', 'energetic', 'generous', 'adventurous', 'free', 'hedonistic', 'lives in the moment', 'indulges in comfort'
        ]
    },
    {
        number: 6,
        type: 6,
        names: 'Operative',
        listings: [
            'pragmatic', 'realistic', 'hardworking', 'action-oriented', 'practical', 'persevering', 'rules-oriented', 'responsible', 'takes things step-by-step', 'assertive'
        ]
    },
    {
        number: 7,
        type: 7,
        names: 'Conventional',
        listings: [
            'conventional', 'detail-oriented', 'cautious', 'patient', 'persistent', 'organized', 'self-disciplined', 'good at planning', 'introvert', 'conservative'
        ]
    },
    {
        number: 8,
        type: 8,
        names: 'Enterprising',
        listings: [
            'proactive', 'flexible', 'ambitious', 'visionary', 'brave to break through', 'good at strategies', 'seizes opportunities', 'good at integrating and planning', 'has a broad network of contacts', 'utilitarian'
        ]
    },
    {
        number: 9,
        type: 9,
        names: 'Dominant',
        listings: [
            'leadership', 'good at organizing', 'dominant', 'ambitious', 'confident', 'aggressive', 'decisive', 'charismatic', 'perfectionist', 'self-centered'
        ]
    },
    {
        number: 10,
        type: 10,
        names: 'Sociable',
        listings: [
            'outgoing', 'positive', 'optimistic', 'hospitable', 'talkative', 'good at socializing', 'observant', 'resilient', 'adaptable', 'versatile', 'enjoys debating'
        ]
    },
    {
        number: 11,
        type: 11,
        names: 'Helpful',
        listings: [
            'kind', 'good-tempered', 'enthusiastic', 'patient', 'behind-the-scenes', 'reliable', 'easy-going', 'amicable', 'self-sacrifice and dedication', 'has no principles'
        ]
    },
    {
        number: 12,
        type: 12,
        names: 'Natural',
        listings: [
            'innocent', 'natural', 'kind', 'contented', 'has simple desires', 'generous', 'sincere', 'honest', 'compassionate', 'overly idealistic'
        ]
    }
]


export { CARDS , SCORES }