<template>
    <div class="card-summary">
        <div class="header">
            <p class="title">{{ $t('card_assessment') }}</p>
            <div class="desc">{{ $t('select_and_flip') }}</div>

        </div>

        <div class="card-container">
            <div 
                :class="['card', { disabled: isDisabled(card) }]"
                v-for="(card, key) in selectedCards"
                :key="key"
                :style="{
                    width: getCardSize.width + 'px',
                    height: getCardSize.height + 'px'
                }"
            >
                <transition name="flip">
                    <img
                        @click="toggleCard(card)"
                        :src="require(`@/assets/img/cards/${card.id}-${language}.png`)"
                        alt="card"
                        :key="card.flipped"
                    >
                </transition>
                <span class="complete" v-if="isCompleted(card)">{{ $t('complete') }}</span>
            </div>

            <div class="size-adjustment">
                <a class="btn" @click="increaseSize()">+</a>
                <span class="percent">{{ size }}%</span>
                <a class="btn" @click="decreaseSize()">-</a>
            </div>
        </div>

        <div class="footer">
            <div class="buttons">
                <button @click="$router.push('/card-assessment')" class="cancel">{{ $t('back') }}</button>
                <button @click="submit" class="next" :disabled="!isDone">{{ $t('done') }}</button>
                <!-- <button @click="submit" class="next" :disabled="false">{{ $t('done') }}</button> -->
            </div>
        </div>

        <transition name="fade">
            <div class="modal-container" v-if="showModal">
                <div class="modal">
                    <div class="reasons-picker" v-if="!showPreviewCard">
                        <div class="header">
                            <p class="title">{{ selectedCard[`${lang}_name`] }}</p>
                            <p class="desc">{{ selectedCard[`${lang}_description`] }}</p>
                            <img @click="handleCloseModal" class="close" :src="require('@/assets/img/close.svg')" alt="close">
                        </div>

                        <span class="tick">{{ $t('tick_off') }}</span>
                        <span>{{ $t('select_5_to_10') }}</span>

                        <div class="reasons-container">
                            <div 
                                class="reason"
                                v-for="(reason, index) in reasons"
                                :key="index"
                            >
                                <div v-if="reason.card_redirect_id !== null">
                                    <div class="label" v-if="reason.card_redirect_id !== 0">
                                        <strong>Note: {{ reason[`${lang}_reason`] }}</strong>
                                        <a class="view-card" @click="viewDetails(reason.card_redirect_id)">View Card Details</a>
                                    </div>

                                    <div class="label" v-else>
                                        <strong>Note: {{ reason[`${lang}_reason`] }}</strong>
                                        <a class="view-card long" @click="addCardFrom54()">Add card (card option wiill be open)</a>
                                    </div>
                                </div>

                                <a
                                    @click="handleCheckUncheckReason(reason)"
                                    :class="{active: isChecked(reason)}"
                                    v-else
                                >
                                    <div class="mark"></div>
                                    <div>
                                        <div class="label">{{ reason[`${lang}_reason`] }}</div>
                                        <!-- <a
                                            class="view-card"
                                            @click="$router.push('/card-assessment')"
                                            v-if="reason.card_redirect_id === 0"
                                        >
                                            Card option will be open
                                        </a> -->
                                    </div>
                                </a>

                            </div>
                        </div>

                        <div class="modal-footer">
                            <button @click="handleNextModal" class="next" :disabled="isNextDisabledModal">{{ $t('submit') }}</button>
                            <button @click="handleCloseModal" class="cancel">{{ $t('close') }}</button>
                        </div>
                    </div>

                    <div class="card-view" v-else>
                        <div class="header">
                            <img @click="closePreviewCard" class="close" :src="require('@/assets/img/close.svg')" alt="close">
                            <img class="card" :src="require(`@/assets/img/cards/${previewCard.id}-${language}.png`)" alt="card">
                            <p class="name">{{ previewCard.name_en }}</p>
                            <p class="desc">{{ previewCard.description_en }}</p>

                            <div class="buttons">
                                <!-- <button @click="$router.push('/card-assessment')" class="return">View Card List</button> -->
                                <button class="existing" v-if="selectedCards.find(x => x.id === previewCard.id)">Already on your list</button>
                                <button @click="addCard" class="add" v-else>Add this card</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>

        <div class="confirm-container" v-if="showTiedModal">
            <div class="confirm">
                <p class="title">{{ $t('you_have_tied_result') }}</p>
                <p class="desc">{{ $t('you_can_pick_another_card') }}</p>
                
                <div class="buttons"> 
                    <button @click="closeTiedModal()" class="close" :disabled="loading">{{ $t('close') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { CARDS } from "@/utils/cards.js"
import { mapFields } from 'vuex-map-fields'

export default {
    data () {
        return {
            cards: CARDS,
            size: 100,
            sizeArray: {
                "100": {
                    width: 165,
                    height: 260
                },
                "80": {
                    width: 155,
                    height: 250
                },
                "60": {
                    width: 145,
                    height: 240
                },
                "40": {
                    width: 135,
                    height: 230
                }
            },
            
            selectedCard: null,
            showModal: false,
            // completed: [],
            currentAnswered: [],

            showPreviewCard: false,
            previewCard: null,

            showTiedModal: false
        }
    },

    computed: {
        ...mapFields(['loading', 'selectedCards', 'reasons', 'score', 'completed', 'hasTie', 'submittedAnswer', 'language', 'ableToAddFromReason']),

        lang () {
            return this.language === 'en' ? 'english' : 'chinese'
        },

        getCardSize() {
            return this.sizeArray[this.size];
        },

        isNextDisabledModal () {
            return this.currentAnswered.length > 4 && this.currentAnswered.length < 11 ? false : true;
        },

        isDone () {
            return this.selectedCards.length === this.completed.length
        }
    },

    created () {
        if (this.selectedCards.length < 1) this.$router.push('card-assessment')
    },

    methods: {
        decreaseSize() {
            if (this.size <= 40) return false;
            this.size = this.size - 20;
        },

        increaseSize() {
            if (this.size >= 100) return false;
            this.size = this.size + 20;
        },

        async toggleCard (card) {
            await this.$store.dispatch('getReasons', {card_id: card.id})

            if (this.isCompleted(card)) {
                this.currentAnswered = this.completed.find(x => x.id === card.id).answers
            }

            this.showModal = true
            this.selectedCard = card
            card.flipped = !card.flipped

        },

        isChecked (val) {
            return this.currentAnswered.find(
                item => item.id === val.id
            )
        },

        isDisabled (val) {
            return this.submittedAnswer.find(item => item.id === val.id)
        },

        isCompleted(val) {
            return this.completed.find(item => item.id === val.id)
        },

        closePreviewCard () {
            this.showPreviewCard = false
            this.previewCard = null
        },

        viewDetails (id) {
            this.showPreviewCard = true
            this.previewCard = this.cards.find(x => x.id === id)
        },

        addCard () {
            // let max = this.submittedAnswer.length > 0 ? 10 : 8
            let max = 10
            if (this.selectedCards.length < max) {
                this.selectedCards.push(this.previewCard)
                this.showPreviewCard = false
            } else {
                this.$store.commit('TOGGLE_BANNER', {
                    status: true,
                    message: this.$t('you_reached_the_maximum_card_selected')
                })
            }
        },

        closeTiedModal () {
            this.showTiedModal = false
            this.$router.push('/card-assessment')
        },

        addCardFrom54 () {
            this.ableToAddFromReason = true
            this.$router.push('/card-assessment')
        },

        handleCheckUncheckReason(val) {
            if (!this.isChecked(val)) {
                if (this.currentAnswered.length > 9) {
                    this.$store.commit('TOGGLE_BANNER', {
                        status: true,
                        message: this.$t('you_reached_the_maximum_reason_selected')
                    })
                    return
                } else {
                    this.currentAnswered = [...this.currentAnswered, val]

                    // for scoring
                    // let typeIds = val.assessment_type_id.split('、')
                    // typeIds.forEach(id => {
                    //     this.score.find(i => i.id === parseInt(id)).count += 1
                    // })
                }
            } else {
                this.currentAnswered = this.currentAnswered.filter(item => item.id !== val.id)

                // for scoring
                // let typeIds = val.assessment_type_id.split('、')
                // typeIds.forEach(id => {
                //     this.score.find(i => i.id === parseInt(id)).count -= 1
                // })
            }
        },

        handleCloseModal () {
            this.selectedCard = null
            this.currentAnswered = []
            this.showModal = false
        },

        handleNextModal() {
            if (!this.isNextDisabledModal) {

                let newVal = {
                    id: this.selectedCard.id,
                    answers: this.currentAnswered
                }

                // if editing answer on previous card
                let index = this.completed.findIndex(x => x.id === newVal.id)
                if (index !== -1) {
                    // for scoring
                    newVal.answers.forEach(x => {
                        let typeIds = x.assessment_type_id.split('、')
                        typeIds.forEach(id => {
                            this.score.find(i => i.id === parseInt(id)).count -= 1
                        })
                    })
                    this.completed.splice(index, 1)
                }

                // for scoring
                newVal.answers.forEach(x => {
                    let typeIds = x.assessment_type_id.split('、')
                    typeIds.forEach(id => {
                        this.score.find(i => i.id === parseInt(id)).count += 1
                    })
                })

                this.completed = [...this.completed, newVal]

                this.currentAnswered = []
                this.selectedCard = null
                
                this.showModal = false
            }
        },

        async submit () {
            this.submittedAnswer = this.completed

            // check if there's tied on result
            // sort first
            let sortedResult = this.score.sort((a, b) => (a.count > b.count) ? -1 : 1)
            // then check if there's tied result for top 3
            for (let index = 0; index < 3; index++) {
                const item = sortedResult[index]

                // if has tied result
                if (item.count === sortedResult[index+1].count) {

                    // if user can still pick a card
                    if (this.submittedAnswer.length < 10) {
                        this.showTiedModal = true
                        this.hasTie = true
                        // this.$router.push('/card-assessment')
                        return
                    } else {
                        this.$router.push('/result-confirmation')
                        return
                    }
                }
            }

            // submit exam because no tie on score
            let total = 0
            this.score.map(x => total += parseInt(x.count))
            
            let form = new FormData()
			let counter = 0
            this.submittedAnswer.forEach((item, index) => {
                form.append(`cards[${index}]`, item.id)

                item.answers.forEach((ans) => {
                    form.append(`reasons[${counter}][card_id]`, ans.card_id)
                    form.append(`reasons[${counter}][card_reason_id]`, ans.id)
                    form.append(`reasons[${counter}][assessment_type_id]`, ans.assessment_type_id)
					counter++
                })
            })

            this.score.forEach((item, index) => {
                form.append(`assessment_result[${item.id}][type_count]`, item.count)
                form.append(`assessment_result[${item.id}][order]`, index+1)
                form.append(`assessment_result[${item.id}][percent]`, Math.round((item.count/total)*100))
            })

            await this.$store.dispatch('submitExam', form)
            // this.$router.push(`/assessment-result/5`)
        }
    }
}
</script>

<style lang="scss" scoped>
.card-summary {
    max-width: 1200px;
    margin: 20px auto;
    padding: 25px;

    .header {
        text-align: center;
        border-bottom: 1px solid #B8B8B8;

        .title {
            font-family: 'Crimson Pro', sans-serif;
            font-weight: 700;
            font-size: 26px;
            margin-bottom: 17px;
        }

        .desc {
            font-size: 14px;
            margin-bottom: 21px;
        }
    }

    .card-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 20px 0 135px;

        .card {
            height: 260px;
            width: 165px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0 20px 15px 0;
            transition: width 1s,height 1s;
            z-index: 1;

            .flip-enter-active {
                transition: all 0.4s ease;
            }
            
            .flip-leave-active {
                display: none;
            }
            
            .flip-enter, .flip-leave {
                transform: rotateY(180deg);
                opacity: 0;
            }

            img {
                width: 100%;
                cursor: pointer;
                height: 220px;
                border-radius: 4px;
                box-shadow: 0 3px 6px #00000047;
                margin-bottom: 15px;
            }

            .complete {
                color: #00AEEF;
                font-size: 18px;
            }

            &.disabled {
                opacity: 0.4;
                pointer-events: none;
            }
        }

        .size-adjustment {
            display: flex;
            align-items: center;
            justify-content: center;
            position: fixed;
            bottom: 100px;
            left: 10px;
            // right: 0;
            margin: auto;
            width: 132px;
            height: 40px;
            background-color: #fff;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 3px;
            padding: 5px 10px;

            @include desktop {
                right: 0;
                left: 0;
            }

            // @include media-breakpoint-down(lg) {
            //     left: unset;
            //     right: 20px;
            // }

            .btn {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 20px;
                font-size: 24px;
                text-align: center;
                color: #161616;
                font-weight: 600;
                cursor: pointer;
            }
            .percent {
                display: block;
                width: 100%;
                font-size: 18px;
                text-align: center;
                color: #161616;
                font-weight: bold;
            }
        }
    }

    .footer {
        display: flex;
        align-items: center;
        position: fixed;
        padding: 10px   ;
        width: 100%;
        box-shadow: 0px -6px 6px #00000047;
        background-color: #ffffff;

        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;

        @include desktop {
            padding: 20px 50px;
        }

        .buttons {
            margin-left: auto;

            button {
                width: 80px;
                font-weight: 600;

                @include desktop {
                    width: 125px;
                }
    
                &.cancel {
                    background-color: #ffffff;
                    border: 1px solid #FF6868;
                    color: #FF6868;
                    margin-right: 16px;
                }
    
                &.next {
                    background-color: #00AEEF;
                    border: 1px solid #00AEEF;
                    color: #ffffff;
                }
    
                &:disabled {
                    cursor: not-allowed;
                    background-color: #B8B8B8;
                    border: 1px solid #B8B8B8;
                    color: #ffffff;
                }
            }
        }

    }

    .fade-enter {
        opacity: 0;
    }
    .fade-enter-active {
        transition: opacity .5s;
    }
    .fade-leave {
        opacity: 1;
    }
    .fade-leave-active {
        transition: opacity .5s;
        opacity: 0;
        pointer-events: none;
    }

    .modal-container {
        position: fixed;
        width: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: #000000a1;
        z-index: 999999;
        overflow-y: auto;

        .modal {
            width: 100%;
            max-height: 90%;
            min-height: 100px;
            background: #ffffff;
            bottom: 0;

            position: absolute;
            padding: 20px;
            overflow: auto;

            .header {
                max-width: 1200px;
                margin: auto;
                
                .title {
                    font-family: 'Crimson Pro', sans-serif;
                    font-weight: 700;
                    font-size: 26px;
                    margin-bottom: 18px;
                    text-align: center;
                }
    
                .desc {
                    font-size: 14px;
                    text-align: center;
                    margin-bottom: 20px;
                }
        
                .close {
                    cursor: pointer;
                    position: absolute;
                    top: 20px;
                    right: 20px;

                    width: 15px;
                    height: 15px;

                    @include desktop {
                        width: unset;
                        height: unset;
                    }
                }
            }

            span {
                display: block;
                margin-bottom: 5px;
                text-align: center;

                &.tick {
                    margin-top: 20px;
                }
            }
    
    
            .reasons-container {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                max-width: 1200px;
                margin: 30px auto;
                color: #221E1F;
    
                .reason {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 35px;
                    text-align: left;

                    @include desktop {
                        width: 50%;
                    }
    
                    a {
                        display: flex;
                        align-items: center;
                        color: #221E1F;
                        cursor: pointer;

                        .mark {
                            min-width: 20px;
                            max-width: 20px;
                            width: 20px;
                            height: 20px;
                            margin-top: 2px;
                            margin-right: 30px;
                            border: 2px solid #B8B8B8;
                            border-radius: 3px;
                            background-color: #fff;
                        }

                        &.active {
                            .mark {
                                position: relative;
                                border: 2px solid #00AEEF;;

                                &:after {
                                    content: "";
                                    width: 10px;
                                    height: 4px;
                                    border: 2px solid #00AEEF;;
                                    border-top: none;
                                    border-right: none;
                                    position: absolute;
                                    left: 2px;
                                    top: 3px;
                                    transform: rotate(-46deg);
                                }
                            }
                        }

                        &.view-card {
                            width: 145px;
                            font-size: 14px;
                            color: #00AEEF;
                            cursor: pointer;
                        }

                        &.long {
                            width: 210px;
                        }
                    }
                }
            }
    
            .modal-footer {
                display: flex;
                flex-direction: row-reverse;
    
                button {
                    width: 125px;
                    font-weight: 600;
    
                    &.cancel {
                        background-color: #ffffff;
                        border: 1px solid #FF6868;
                        color: #FF6868;
                        margin-right: 16px;
                    }
    
                    &.next {
                        background-color: #00AEEF;
                        border: 1px solid #00AEEF;
                        color: #ffffff;
                    }
    
                    &:disabled {
                        cursor: not-allowed;
                        background-color: #B8B8B8;
                        border: 1px solid #B8B8B8;
                        color: #ffffff;
                    }
                }
            }

            .card-view {
                .header {
                    margin-top: 50px;
                    border-bottom: unset;
                }

                .card {
                    width: 300px;
                    height: 430px;
                    margin-bottom: 20px;
                    border: 1px solid #707070;
                    border-radius: 4px;

                    @include desktop {
                        width: 329px;
                        height: 428px;
                    }
                }

                .name {
                    font-size: 18px;
                    font-weight: 600;
                    margin-bottom: 14px;
                }

                .desc {
                    font-size: 16px;
                }

                .buttons {
                    display: flex;
                    justify-content: center;

                    button {
                        font-size: 15px;
                        font-weight: 600;

                        &.return {
                            color: #FF6868;
                            border: 1px solid #FF6868;
                            background: #ffffff;
                            margin-right: 15px;
                        }

                        &.add {
                            color: #ffffff;
                            border: 1px solid #00AEEF;
                            background: #00AEEF;
                        }

                        &.existing {
                            color: #ffffff;
                            border: 1px solid #B8B8B8;
                            background: #B8B8B8;
                            pointer-events: none;
                        }
                    }
                }
            }
        }
    }

    .confirm-container {
        position: fixed;
        width: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: #000000a1;
        z-index: 999999+1;
        overflow-y: auto;
        display: flex;
        justify-content: center;
        align-items: center;

        .confirm {
            width: 400px;
            z-index: 999;
            padding : 90px 50px 20px;
            box-shadow: 0px 3px 6px #00000047;
            display: flex;
            flex-direction: column;
            text-align: center;
    
            background-image: url('~@/assets/img/home_upperbg.png');
            background-repeat: no-repeat;
            background-color: #ffffff;
            background-size: 100%;
    
            .title {
                color: #00AEEF;
                font-weight: 600;
                font-size: 20px;
                margin-bottom: 30px;
            }
    
            .desc {
                width: 260px;
                margin: auto;
                font-size: 14px;
                margin-bottom: 30px;
            }

            .buttons {
                display: flex;

                .close {
                    color: #FF6868;
                    border: 1px solid #FF6868;
                    background: #ffffff;
                    font-size: 14px;
                    width: 120px;
                    margin: auto;
                }
            }
    
        }
    }
}
</style>